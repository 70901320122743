import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')


   export const encrypt=data=>CryptoJS.AES.encrypt(data, 'gaatschool').toString();
 
  export const decrypt=data=>{
  var byte =  CryptoJS.AES.decrypt(data, 'gaatschool')
  return byte.toString(CryptoJS.enc.Utf8)
  } 



  export const verifyRouter =(route)=>{

const groupLink = localStorage.getItem('_gaatmnulk')?decrypt(localStorage.getItem('_gaatmnulk')):[]
return groupLink.includes(route)?true:false 

  }
  
export const userToken =Cookies.get('gaatkarfsh')?decrypt(Cookies.get('gaatkarfsh')):[]