import React, {useState, useEffect} from 'react'
import {Alerts} from '../component/notify'
import {ServerUrl, ImagesUrl, config, mainUrl, Token} from '../component/include'
import axios from 'axios'
import Cookies from 'js-cookie'
import {encrypt} from '../component/authentication'
import Preloader from '../component/preloader'
import { LoadEffect } from '../component/loader'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
 const Login =()=> {

	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	const [user, setUser] = useState({
		email:'',
		password:'',
		remember:false,
		date:new Date().toISOString().slice(0,10),
		dateTime:new Date().toISOString().slice(0,19)
    })
    const [errors, setErrors] = useState({});

    const handleChange = event =>{		
        let {name, value} = event.target;	
        if(name==='remember'){
            value = !user.remember
        }	
       setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }
    
	
	let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
        	
	function submit(event){  
		event.preventDefault();

		if(navigator.onLine){
		setNotice({...notice, isLoading: true}) 	
           
        let formData = user;
        const fd = new FormData();
        for(let k in formData){
          fd.append(k, formData[k])
        }
           let url = ServerUrl+'/login_controller.php?tablename=tbl_login_user';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){
			
			//1000 * 60 * SESSION_IDEL_MINUTES
			var inSixtyMinutes = Date.now() + 419 * 60 * 1000;
			var inOneMinutes = Date.now() + 418 * 60 * 1000;
			  var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);
			  
			  localStorage.setItem('_gaatexpt', inSixtyMinutes)
				localStorage.setItem('_gaatbexpt', inOneMinutes)
				Cookies.set('gaatkaustnm', encrypt(response.data.staffName), {expires: inOneHours })
			Cookies.set('gaatkauscd', encrypt(response.data.code), {expires: inOneHours })
			Cookies.set('gaatkagpcd', encrypt(response.data.groupId), {expires: inOneHours })
			Cookies.set('gaatkamtk', encrypt(response.data.jwt),  {expires: inOneHours })   
			Cookies.set('gaatkarfsh', encrypt(response.data.userToken),  {expires: inOneHours })

		
			Alerts('Welcome!', 'success', response.data.message)
			
			var queryString = new URLSearchParams(window.location.search).get("refferer")
	   if(queryString!==null){
		window.open(queryString, '_self')
	  }else{
		window.open('/dashboard', '_self')
	  }  
			}else if (response.data.type ==='error'){
				Alerts('Error!', 'info', response.data.message)
			}    
		})
		.catch((error)=>{
			Alerts('Error!', 'info', error.message)
			 
		}).finally(()=>{
            setNotice({...notice, 
				isLoading: false
			}) 
        })   
		
	 }else{
		Alerts('Error!', 'info', 'No internet connection')
	} 
 }
 useEffect(()=>{  
	if(Token!==undefined){
		window.open('/dashboard', '_self')
		 } 
},[]);


        return (<>
          <Preloader />

		
		  <section style={{background: `url(${ImagesUrl}/background/f.jpeg) `, backgroundSize:'cover'}} 
		  className="login-block">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    
	<form className="md-float-material form-material" role={'presentation'} autoComplete='off' onSubmit={submit}>
		
	<div className="auth-box card">
		<div className="card-block">
			<div className="row m-b-20">
				<div className="col-md-12">
				<div className="text-center">
		<img src={ImagesUrl+'/about/logo.png'} className="img-100" alt="logo.png" />
		</div>
					<h3 className="text-center"  >Administrator </h3>
				</div>
			</div>
			<div className="form-group form-primary">

				<input type="email" id="email" name="email" required autoComplete='off' role={'presentation'} value={user.email} placeholder="Your Email Address" onChange={handleChange} className={errors.email  ? 'form-control formerror' : 'form-control'} /> 
				<span className=" form-bar error">{ errors.email}</span>

			</div>
			<div className="form-group form-primary">
			<input type="password" id="password" name="password" required autoComplete='off' role={'presentation'} value={user.password} placeholder="Password" onChange={handleChange} className={errors.password  ? 'form-control formerror' : 'form-control'} /> 
			<span className=" form-bar error">{errors.password }</span>


			</div>
			<div className="row m-t-25 text-left">
				<div className="col-12">
					<div className="checkbox-fade fade-in-primary d-">
						<label>
							<input type="checkbox" checked={user.remember} id="remember" name="remember" onChange={handleChange} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-primary"></i></span>
							<span className="text-inverse">Remember me</span>
						</label>
					</div>
					<div className="forgot-phone text-right f-right">
						<a href="/forgot_password" className="text-right f-w-600"> Forgot Password?</a>
					</div>
				</div>
			</div>
			<div className="row m-t-30">
				<div className="col-md-12">
				

				{notice.isLoading ?	<button type="button" disabled className="btn btn-danger btn-md btn-block  text-center m-b-20"><i className='fa fa-refresh fa-spin'></i> Please wait ... </button>:
				<button type="submit"  className="btn btn-danger btn-md btn-block waves-effect waves-light text-center m-b-20">Sign in</button>}
				</div>
			</div>
			<hr/>
			<div className="row">
				<div className="col-md-10">
					<p className="text-inverse text-left m-b-0">Thank you.</p>
					<p className="text-inverse text-left"><a href="https://www.kayus.com.ng" target={'_blank'}><b className="f-w-600">Powered By KAYUS</b></a></p>
				</div>
				<div className="col-md-2">
					
				<img src={ImagesUrl+'/about/kayus.png'} alt="KAYUS" className="img-30" />
				</div>
			</div>
		</div>
                            </div>
                        </form>
                </div>
            </div>
        </div>
    </section>



         
</> 

 );
        
}

export default React.memo(Login)  