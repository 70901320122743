import React, { useEffect } from 'react'
 import Cookies from 'js-cookie'
 
const LogOut =()=> {


  const clearCookies=()=>{
    Cookies.remove('gaatkaustnm')
     Cookies.remove('gaatkauscd')	
    Cookies.remove('gaatkagpcd')
    Cookies.remove('gaatkamtk')
    Cookies.remove('gaatkarfsh')
    localStorage.setItem('_gaatexpt', Date.now())
    localStorage.setItem('_gaatbexpt', Date.now())
    localStorage.setItem('_gaatmnulk', Date.now())
    localStorage.setItem('gaatkarls', '[]')
  }

 useEffect(()=>{  
  clearCookies()
  window.open("/", '_self')
},[]); 

  return (
    <div>...please wait</div>
  )
}

export default LogOut