import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, selectStyles} from '../component/include'
import Select from 'react-select'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate, sort, formatGroupLabel, getClassName, getResultHead } from '../component/globalFunction'
import { useDispatch, useSelector } from 'react-redux'

import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import Error403 from '../settings/error403'
 const ResultSetup =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [examType, setExamType] = useState({
        examsession:[],
        examterm:'',
        remarks:'',
        status:'Active',
        assessmentClass:[],
        assessmentterm:[],
        sessionterm:[],
        studentClass:[],
        maxScore:100,
        examDate:''
    })

    const [classTeacher, setClassTeacher] = useState([]);
    const [errors, setErrors] = useState({});
    const schoolSession  = useSelector(state => state.rootReducer.schoolSession);
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const staff  = useSelector(state => state.rootReducer.staff);
    const [data, setData] =  useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmittingResult, setIsSubmittingResult] = useState(false);
    const [exams, setExams]=useState([])
    const [allSubject, setAllSubject]=useState([]);
    const [students, setStudents] = useState([]);
    const [classSubject, setClassSubject] = useState([]);
    const [assessment, setAssessment]=useState([])
    const [resultSubject, setResultSubject]=useState([])
    const [examResult, setExamResult]=useState([])
    const [examAssessment, setExamAssessment] = useState([]); 
    const [settings, setSettings]=useState([])
    const [standards, setStandards]=useState([])
    const [category, setCategory]=useState([])


const [activeStep, setActiveStep] = useState(1);
const steps = [{title: 'Exam Type'}, {title: 'Assessment  Type'}, {title: 'Exam Schedule'}, {title: 'Result Slip'}]

const handleOnClickStepper = (step) => {
        setActiveStep(step);        
        
    }

    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
       
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
       
    }, [activeStep])


  

    const fetchExams =()=>{
        var sql ="SELECT ID, code, term, session, publish, status, dateCreate from tbl_exam_type   order by session ASC" ;

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    setExams(result.data)
})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}


       
    const  handleFetchClassStandard =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetch_controller/tbl_class_standards';
        axios.post(url, fd, config)
    .then(result =>   setData(result.data)
    )
    .catch((error)=>{
       // Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    }) }



 const fetchCategory =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_standards_setting/category/ID/1';
        axios.post(url, fd, config)
        .then(result =>setCategory(result.data.sort(sort)))
    }
        

        const fetchSettings =()=>{
            const fd = new FormData();
        fd.append('jwt', Token);
            let url = ServerUrl + '/fetchField/tbl_result_slip/settings/ID/1';
            axios.post(url, fd, config).then(result=>{
                setSettings(result.data)
              
            })
        }
    const fetchClassSubject =(sectionID)=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_class_subject/subjectDetails/sectionID/'+sectionID;
        axios.post(url, fd, config)
        .then(result =>{
            setClassSubject(result.data)
        })
    }

    const  handleFetchAllSubject =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetch_controller/tbl_subject';
        axios.post(url, fd, config)
    .then(result => setAllSubject(result.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    }) }

    const fetchStudents =(sectionID)=>{
        var sql ="Select code, admissionNumber  from tbl_students where admissionStatus ='Studying' and sectionID='"+sectionID+"'" ;
        
const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    setStudents(result.data)})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
         }
    
         const fetchExamResult =()=>{
            var sql ="SELECT CONCAT(t.session, ' ', t.term) as exam, r.ID, r.uniqueCode, r.examCode, r.classID, r.sectionID, r.dateCreate, r.code  from tbl_exam_result r, tbl_exam_type t where  t.publish ='No' and t.status='Active' and r.examCode = t.code group by r.uniqueCode order by r.ID DESC" ;
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        setExamResult(result.data)
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }

    const fetchExamAssessment =()=>{
        var sql ="SELECT CONCAT(t.session, ' ', t.term) as exam, r.ID, r.uniqueCode, r.classID, r.sectionID, r.dateCreate, r.code, r.examCode  from tbl_assessment_result r, tbl_exam_type t where r.examCode = t.code and t.publish ='No' and t.status='Active' group by r.uniqueCode order by r.ID DESC" ;
const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    setExamAssessment(result.data)
})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}

    const handleSelect = (option, action)=>{
        setExamType({...examType, [action.name]: option });
       setErrors({...errors, [action.name]:''})
       if(action.name==='studentClass'){
        fetchClassSubject(option.value)
        fetchStudents(option.value)
        fetchClassTeaher(option.classID, option.value)
       }
    }

    const handleSelectClass = (option, action)=>{
        setExamType({...examType, [action.name]: option });
       setErrors({...errors, [action.name]:''})
       var standard = data.filter(item=>item.sectionID===option.value)
       if(standard.length.length!==0){

       // console.log(JSON.parse(standard[0].standardsDetails))
        setAssessment(JSON.parse(standard[0].standardsDetails))
        
       }else{
        setAssessment([]) 
       }
       fetchStudents(option.value)
    }

    const handleChange = event =>{
        const {name, value} = event.target
       setExamType({...examType, [name] : value });
       setErrors({...errors, [name]:''})
    }

    const handleSubmitClassResult = event =>{
        event.preventDefault();    
        setErrors(ValidateResult(examType));
        setIsSubmittingResult(true);
    }

    const ValidateResult=(values)=>{
        let errors = {};   

        if(values.sessionterm.length===0){
            errors.sessionterm ='Please select session Term';
        } 
        
        if(students.length===0){
            errors.studentClass ='There are no student registered in the selected class';
        }
        if(classSubject.length===0){
            errors.studentClass ='There are no subject define for the selected class';
        }
        if(values.studentClass.length===0){
            errors.studentClass ='Please select class';
        } 
        if(!values.maxScore){
            errors.maxScore ='Please enter total mark obtainable';
        }
       /*  if(!values.examDate){
            errors.examDate ='Please enter exam date';
        } */
         if(examResult.filter(item=>item.examCode ===values.sessionterm.value && item.sectionID ===values.studentClass.value).length!==0){
            errors.sessionterm ='Exam type already created for the selected class';
            Alerts('Error!', 'danger', errors.sessionterm)
        } 
        return errors;
        }


const getResultSubject =()=>{
    
    var subj = classSubject;
    var subjectList = [];
    for (var i = 0; i< subj.length;  i++) {
        subjectList.push(
            {
                code:Math.random().toString(36).substr(2,9),
                admissionNumber:'', 
                studentCode:'',
                examDate:examType.examDate,
                subjectCode:subj[i].subjectID,
                subjectName:getSubjectName(subj[i].subjectID, 'subjectName'),  
                order:i+1,
                maxMark:examType.maxScore,
                classID:examType.studentClass.classID,
                sectionID:examType.studentClass.value, 
                examCode:examType.sessionterm.value,
                firstCA:'',
                secondCA:'',
                exam:'',
                total:'',
                subjectPosition:'',
                grade:'',
                remark:'',
                position:'', 
                totalAverage:'',
                teacher:getSubjectName(subj[i].subjectID, 'teacher')
            });
    }
    setResultSubject(subjectList)
    setIsSubmittingResult(false)
}

const submitResult =()=>{

    if(resultSubject.length===0){
        Alerts('Error!', 'danger', 'There must be at least one subject to save schedule')
    }else if(classTeacher.length===0){
        Alerts('Error!', 'danger', 'Please assign form tutor/class teacher to the selected class first')
    }else{

    
    setNotice({...notice, isLoading: true}) 
    var subj = resultSubject;
    var subjectList = [];
    var std = students;
    for (var i = 0; i < std.length; i++){
        for (var j = 0; j < subj.length; j++) {
            subjectList.push(
                {
                    code:Math.random().toString(36).substr(2,9),
                    admissionNumber:std[i].admissionNumber, 
                    studentCode:std[i].code,
                    examDate:subj[j].examDate,
                    subjectCode:subj[j].subjectCode, 
                    subjectName:subj[j].subjectName,
                    order:subj[j].order,
                    maxMark:subj[j].maxMark,
                    classID:examType.studentClass.classID,
                    sectionID:examType.studentClass.value, 
                    examCode:examType.sessionterm.value,
                    teacher:subj[j].teacher
                    
                });
        };
    }
       
    var remarkList = [];
    for (var i = 0; i < std.length; i++){
            remarkList.push(
                {
                    studentCode:std[i].code,
                    classID:examType.studentClass.classID,
                    sectionID:examType.studentClass.value, 
                    examCode:examType.sessionterm.value
                    
                });
    }

        var headName = getResultHead(examType.studentClass.classtype)==='Secondary'?
        String(settings.map((st)=>st.headofSecondary)): getResultHead(examType.studentClass.classtype)==='Primary'?
        String(settings.map((st)=>st.headofPrimary)): String(settings.map((st)=>st.headofNursery))
        
        var teacher = classTeacher;
        var list = [];
        for (var i = 0; i< teacher.length;  i++) {
            list.push(teacher[i].code);
                }

        const fd = new FormData(); 
        fd.append('uniqueCode', 'rs'+Math.random().toString(36).substr(2,9))
    fd.append('subjectList', JSON.stringify(subjectList, null, 2));
    fd.append('remarkList', JSON.stringify(remarkList, null, 2));
    fd.append('jwt', Token)

    fd.append('teacherName', JSON.stringify(list, null, 2));
    fd.append('headName', headName)
    fd.append('resultHead', getResultHead(examType.studentClass.classtype))
    fd.append('headofSchool', String(settings.map((st)=>st.headofSchool)))

    let url = ServerUrl+'/save_controller/tbl_exam_result';
            axios.post(url, fd, config)
        .then(response =>{
            if (response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
            }else if (response.data.type ==='info'){
                Alerts('Update!', 'info', response.data.message)
            }  else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }       
        })
        .catch(error=>Alerts('Error!', 'danger', JSON.stringify(error.message)) )
    .finally(()=>{
    setNotice({...notice, isLoading: false})
  window.location.reload()  
    })
 
}
}

const fetchStandards =()=>{
    const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl + '/fetchField/tbl_standards_setting/assessment/ID/1';
    axios.post(url, fd, config)
    .then(result =>setStandards(result.data.sort((a, b) =>(a.categoryCode > b.categoryCode) ? 1 : -1)))
}



const submitAssessment =()=>{

    if(assessment.length===0){
        Alerts('Error!', 'danger', 'There must be at least one assessment  to save standards')
    } else if(students.length===0){
        Alerts('Error!', 'danger', 'There are no student found in the selected class')
    } else if(examType.assessmentterm.length===0){
        Alerts('Error!', 'danger', 'Please select exam type')
    }else{

    
   setNotice({...notice, isLoading: true}) 
    var subj = assessment;
    var assessmentList = [];
    var std = students;
    for (var i = 0; i < std.length; i++){
        for (var j = 0; j < subj.length; j++) {
            assessmentList.push(
                {
                    code:Math.random().toString(36).substr(2,9),
                    admissionNumber:std[i].admissionNumber, 
                    studentCode:std[i].code,
                    assessmentCode:subj[j].assessmentID ,
                    grade:'',
                    classID:examType.assessmentClass.classID,
                    sectionID:examType.assessmentClass.value, 
                    examCode:examType.assessmentterm.value
                    
                });
        };
    }       
    
        const fd = new FormData(); 

        fd.append('uniqueCode', 'as'+Math.random().toString(36).substr(2,9))
    fd.append('assessmentList', JSON.stringify(assessmentList, null, 2));
    fd.append('jwt', Token)

    let url = ServerUrl+'/save_controller/tbl_assessment_result';
            axios.post(url, fd, config)
        .then(response =>{
            if (response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
            }else if (response.data.type ==='info'){
                Alerts('Update!', 'info', response.data.message)
            } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }       
        })
        .catch(error=>Alerts('Error!', 'danger', JSON.stringify(error.message)) )
    .finally(()=>{
        window.location.reload()   
    }) 
 
}
}


const getSubjectName = (code, field) =>{        
    const result = allSubject&&allSubject.filter(list=>list.code === code);
    return   String(result.map(c=>c[field]));
    }




    const handleSubmit = event =>{
        event.preventDefault();  
        
        let errors = {};   
        if(examType.examsession.length===0){
            errors.examsession ='Please select exam session';
        } 
        if(examType.examterm.length===0){
            errors.examterm ='Please select exam term';
        } 
        if (exams.filter(item=>item.session ===examType.examsession.value && item.term ===examType.examterm.value).length!==0){
            errors.examsession ='Session and term already created';
        }



    setErrors(errors)

    if(Object.keys(errors).length === 0){

submit()
    }

    }

function submit(){
            setNotice({...notice, isLoading: true})    
            
                const fd = new FormData(); 
                let formData = examType; 
               for(let k in formData){
                   fd.append(k,formData[k]);
               };   
               fd.append('session', examType.examsession.value);
               fd.append('term', examType.examterm.value);
               fd.append('code', 't'+Math.random().toString(36).substr(2,9));
               fd.append('jwt', Token)
             let url = ServerUrl+'/save_controller/tbl_exam_type';
                      axios.post(url, fd, config)
                .then(response =>{
                    if (response.data.type ==='success'){
                        Alerts('Saved!', 'success', response.data.message)
                    } else{
                        Alerts('Error!', 'danger', JSON.stringify(response.data))
                }
                        
                })
                .catch(error=>Alerts('Error!', 'danger', JSON.stringify(error.message)) )
    .finally(()=>{
        setNotice({...notice, 
            isLoading: false})
            fetchExams()
            setIsSubmitting(false);
            setExamType({...examType,
                examsession:[],
                examterm:'',
                    remarks:'',
                    status:'Active'
            }) 
        })
            }



const TableRecord=(props)=>{

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('ID', ID);
            fd.append('jwt', Token)
            let url = ServerUrl+'/updateCustom_controller/tbl_exam_type'
            axios.post(url, fd, config)
            //.then(result => console.log(result.data))
            .then()
            .catch((error)=>Alerts('Error!', 'danger', error.message)) 
        } 


            const { SearchBar } = Search;
            
            const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
            );
            const options = {
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500}, {text: '1000', value: 1000},
            { text: 'All', value: props.data.length }]
            
            };
            return  <ToolkitProvider search columnToggle 
                        keyField='ID' data={ props.data } columns={ props.columns } >
                            
                            {
                                props => (
                                
                                <div className="form-group">
                                    <SearchBar
                                    { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                     
                                     
          
                        <BootstrapTable
                                    { ...props.baseProps }
                                                striped
                                            hover
                                            condensed
                                            noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                        pagination={paginationFactory(options) }
                                        cellEdit={ cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        autoSelectText: true,
                                beforeSaveCell: (oldValue, newValue, row, column) => {
                                if(oldValue !==newValue){
                                handleUpdate(column, newValue, row.ID);
                                
                                return true;
                                }
                                
                            }
                                        }) }
                                    />
                                </div>
                                )
                            }
        </ToolkitProvider>
        }

       

        const handleRemoveSubject = (code)=>{ 
            setResultSubject(resultSubject.filter(item=>item.code!==code).sort(sort))
        }
        const handleRemoveAssessment = (code)=>{ 
           setAssessment(assessment.filter(item=>item.assessmentID!==code))
        }

        const getCategory=(code)=>{
            const result = category && category.filter(list=>list.code===code);
          const answer = result.map((c)=>c.categoryName);
          return  answer
        }
        const getStandardName = (ID, field) =>{        
            const result = standards&&standards.filter(list=>list.code === ID);
            return   String(result.map(c=>c[field]));
            }

          const tableAssesmentHeader = [
            {dataField: 'category', text: 'Category',  editable: false, formatter:(cell, row)=>getCategory(getStandardName(row.assessmentID, 'categoryCode'))},
                {dataField: 'assessmentID', text: 'Name',  editable: false, formatter:(cell)=>getStandardName(cell, 'assesmentName')},
                {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger  btn-sm" onClick={()=>handleRemoveAssessment(row.assessmentID)} title="Delete"><i className="fa fa-trash"></i> </button>, editable: false},        
             ];


             const tableExamAssessmentHeader = [
            
                {dataField: 'exam', text: 'Standards Created',  editable: false},
                    {dataField: 'classID', text: 'Class',  editable: false, formatter:(cell, row)=>getClassName(row.classID, row.sectionID, schoolClass)},
                    {dataField: 'dateCreate', text: 'Creation Date',  sort: true, editable: false, formatter: (cell)=>longDate(cell)},
                   
          
                   {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
                    <WarningModal  handleAction={()=>handleDeleteResult(row.uniqueCode, 'tbl_assessment_result')} mID={row.uniqueCode} message='All student result for this exam will be deleted. Are you realy sure?' />
                    <button type="button" className="btn btn-danger  btn-sm delete" data-toggle="modal"   data-target={`#warningdialog-${row.uniqueCode}`} title="Delete"><i className="fa fa-trash"></i> </button>
                    
                    </div>, editable: false},   
                 ];


        const tableExamResultHeader = [
            
        {dataField: 'exam', text: 'Exam Created',  editable: false},
            {dataField: 'classID', text: 'Class',  editable: false, formatter:(cell, row)=>getClassName(row.classID, row.sectionID, schoolClass)},
            {dataField: 'dateCreate', text: 'Creation Date',  sort: true, editable: false, formatter: (cell)=>longDate(cell)},
           
         {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
            <WarningModal  handleAction={()=>handleDeleteResult(row.uniqueCode, 'tbl_exam_result')} mID={row.uniqueCode} message='All student result for this exam will be deleted. Are you realy sure?' />
            <button type="button" className="btn btn-danger btn-sm delete" data-toggle="modal"   data-target={`#warningdialog-${row.uniqueCode}`} title="Delete"><i className="fa fa-trash"></i> </button>
            
            </div>, editable: false},   
         ];


        const tableExamTypeHeader = [
           
            {dataField: 'session', text: 'Session',  editable: false},
              {dataField: 'term', text: 'Exam Term',  sort: true, editor: {
                type: Type.SELECT, options:[
                            {value:'AUTUMN TERM', label:'AUTUMN TERM'},
                            {value:'SPRING TERM', label:'SPRING TERM'},
                            {value:'SUMMER TERM', label:'SUMMER TERM'},
                            {value:'MOCK EXAM', label:'MOCK EXAM'}
                ] } },
              
            {dataField: 'dateCreate', text: 'Creation Date',  sort: true, editable: false, formatter: (cell)=>longDate(cell)},
            {dataField: 'status', text: 'Status',  sort: true, editor: {
                type: Type.SELECT, options:[
                            {value:'Active', label:'Active'},
                            {value:'Inactive', label:'Inactive'}
                ] }, formatter: (cell, row)=>row.status==='Active'?<span className="pcoded-badge label label-success">{row.status}</span>: <span className="pcoded-badge label label-danger">{row.status}</span>},
                {dataField: 'publish', text: 'Publish',  sort: true, editor: {
                    type: Type.SELECT, options:[
                                {value:'Yes', label:'Yes'},
                                {value:'No', label:'No'}
                    ] }, formatter: (cell, row)=>row.publish==='Yes'?<span className="pcoded-badge label label-success">{cell}</span>: <span className="pcoded-badge label label-danger">{cell}</span>},

        /*  {dataField: 'ID', text: 'Action', formatter: (cell)=><div>
            <WarningModal  handleAction={()=>handleDelete(cell)} mID={cell} message='Once deleted, you will no be able to recover this record. Are you realy sure?' />
            <button type="button" className="btn btn-danger  btn-sm" data-toggle="modal"   data-target={`#warningdialog-${cell}`} title="Delete"><i className="fa fa-trash"></i> </button>
            
            </div>, editable: false}  */ 
         ];

        

         const TableExamSubject=(props)=>{
            const handleUpdate=(column, newValue, code)=>{ 
                var newSubject = resultSubject.filter(item=>item.code!==code)
                let edit = resultSubject.filter(item=>item.code===code)
                    edit[0][column.dataField] = newValue
                setResultSubject(edit.concat(newSubject).sort(sort)) 
                } 
        
        const { SearchBar } = Search;
        
        const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
        const options = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
        { text: 'All', value: props.data.length }]
        
        };
        return  <ToolkitProvider search columnToggle 
                    keyField='code' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            
                            <div className="form-group">
                                <SearchBar
                                { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                    
                                    <div className="btn-group pull-right">
      
      <button type="button" className="btn btn-success" onClick={submitResult}>
      <i className="fa fa-save"></i> Save Result </button>
      
      </div>         
                    <BootstrapTable
                                { ...props.baseProps }
                                            striped
                                        hover
                                        condensed
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                    cellEdit={ cellEditFactory({
                                    mode: 'click',
                                    blurToSave: true,
                                    autoSelectText: true,
                            beforeSaveCell: (oldValue, newValue, row, column) => {
                            if(oldValue !==newValue){
                            handleUpdate(column, newValue, row.code);
                            
                            return true;
                            }
                            
                        }
                                    }) }
                                />
                            </div>
                            )
                        }
    </ToolkitProvider>
    }


    const TableExamAssessment=(props)=>{
    
    const { SearchBar } = Search;
    
    const customTotal = (from, to, size) => (
    <span >&nbsp;Showing { from } to { to } of { size } items</span>
    );
    const options = {
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
    { text: 'All', value: props.data.length }]
    
    };
    return  <ToolkitProvider search columnToggle 
                keyField='assessmentID' data={ props.data } columns={ props.columns } >
                    
                    {
                        props => (
                        
                        <div className="form-group">
                            <SearchBar
                            { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                
                                <div className="btn-group pull-right">
  
  <button type="button" className="btn btn-success" onClick={submitAssessment}>
  <i className="fa fa-save"></i> Save Standards  </button>
  
  </div>         
                <BootstrapTable
                            { ...props.baseProps }
                                        striped
                                    hover
                                    condensed
                                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                pagination={paginationFactory(options) }
                            />
                        </div>
                        )
                    }
</ToolkitProvider>
}
         const tableExamSubjectHeader = [
            
            {dataField: 'order', text: 'Order',  validator: (newValue, row, column) => {
                if (!Number(newValue)) {
                  return {
                    valid: false,
                    message: 'This field required a valid number'
                  };
                }
                return true;
              }},
              
              {dataField: 'examDate', text: 'Date',  sort: true, editor: {
                type: Type.DATE}, formatter: (cell)=>longDate(cell)},
              {dataField: 'subjectName', text: 'Subject',  editable: false},
            {dataField: 'maxMark', text: 'Max Marks',  validator: (newValue, row, column) => {
                        if (!Number(newValue)) {
                          return {
                            valid: false,
                            message: 'This field required a valid number'
                          };
                        }
                        return true;
                      }},
                    {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleRemoveSubject(row.code)} title="Delete"><i className="fa fa-trash"></i> </button>, editable: false}        
            ];
       

         const handleDelete =(ID)=>{
            let close =   document.getElementById('btnWarningDialog-'+ID)
            close.click();
           
            setNotice({...notice, 
                isLoading: true}) 
    
            const fd = new FormData();
            fd.append('ID', 'ID')
            fd.append('data', ID)
            fd.append('jwt', Token);
        let url = ServerUrl+'/delete_controller/tbl_exam_type';
              axios.post(url, fd, config)
              .then(response =>{
               if(response.data.type ==='success'){
                  Alerts('Saved!', 'default', response.data.message)
                      } else{
                  Alerts('Error!', 'danger', JSON.stringify(response.data))
                      }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                  fetchExams()
                  setNotice({...notice, 
                      isLoading: false
                  })
              }) 
    }

    const handleDeleteResult=(uniqueCode, table)=>{
        let close =   document.getElementById('btnWarningDialog-'+uniqueCode)
        close.click();
        
if(table==='tbl_exam_result'){
    handleDeleteResultRemark(uniqueCode)
}

        setNotice({...notice, 
            isLoading: true}) 

        const fd = new FormData();
        fd.append('ID', 'uniqueCode')
        fd.append('data', uniqueCode)
        fd.append('jwt', Token);
    let url = ServerUrl+'/delete_controller/'+table;
          axios.post(url, fd, config)
          .then(response =>{
           if(response.data.type ==='success'){
              Alerts('Saved!', 'default', response.data.message)
                  } else{
              Alerts('Error!', 'danger', JSON.stringify(response.data))
                  }   
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
            fetchExamResult()
            fetchExamAssessment()
              setNotice({...notice,  isLoading: false  })
          }) 

    }


    const handleDeleteResultRemark=(uniqueCode)=>{
        const fd = new FormData();
        fd.append('ID', 'uniqueCode')
        fd.append('data', uniqueCode)
        fd.append('jwt', Token);
    let url = ServerUrl+'/delete_controller/tbl_result_remarks'
          axios.post(url, fd, config)
          .then(response =>{
           if(response.data.type ==='success'){
              //Alerts('Saved!', 'default', response.data.message)
                  } else{
              Alerts('Error!', 'danger', JSON.stringify(response.data))
                  }   
          })
    }


        
        const handleChangeSettings = (e)=>{
            const std =JSON.parse(JSON.stringify(settings))   
                const value =e.target.value;
               std[0][e.target.name] = value;
            setSettings(std);
        }
       

        const handleChangeSelect = (option, action)=>{
            const std =JSON.parse(JSON.stringify(settings)) 
               std[0][action.name] = option.value;
            setSettings(std);

        }


        
const  handleSubmitResultSettings = event =>{
    event.preventDefault();

    setNotice({...notice, isLoading: true})  

        const fd = new FormData(); 
        fd.append('settings', JSON.stringify(settings))
        fd.append('jwt', Token);
         let url = ServerUrl+'/update_controller/tbl_result_slip';
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='info'){
                Alerts('Updated!', 'default', response.data.message)
                    } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }   
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                fetchSettings()
                  setNotice({...notice,  isLoading: false  })
                  setActiveStep(1)
              })   
}

    


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmittingResult){
        getResultSubject()
        }
        },[errors])

    
        const getStaffField = (code, field) =>{   
    
            const result = staff&&staff.filter(list=>list.code===code);
            const answer = result.map((c)=>c[field] ); 
            return  String(answer);
           }



           const fetchClassTeaher =(classID, sectionID)=>{
            var newStaff = staff&&staff.filter((item=>item.classID===classID&&item.sectionID===sectionID))
            setClassTeacher(newStaff)
        }



    useEffect(()=>{
       // setIsAuthenticated(verifyRouter(location.pathname))
        fetchStandards()
        fetchExams()
        handleFetchAllSubject()
        fetchExamResult()
        fetchExamAssessment()
       handleFetchClassStandard()
       fetchSettings();
       fetchCategory()
    },[]);
  


        return (  
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
        <PageHeader title="Create Result">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Create Result</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Create Result</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-inverse btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '10px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="examType">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">
      
  <div className="row">
     
     <section className="col-md-6">
           <div className="form-group">
         <label> Exam Session</label>
         <Select   options={schoolSession.map((dat, id)=> {
                                    return {key:id, value:dat.startYear, label:dat.startYear};
                                  })}
onChange={handleSelect}  name="examsession" value={examType.examsession} styles={selectStyles} className={errors.examsession ? 'form-control form-control-danger' : ''} />
                     <span style={{color:'red'}}>{errors.examsession && errors.examsession}</span>
            </div>
     
     </section>
     
     <section className="col-md-6">
           <div className="form-group">
         <label> Exam Type</label>
         <Select  options={[{value:'AUTUMN TERM', label:'AUTUMN TERM'},
                            {value:'SPRING TERM', label:'SPRING TERM'},
                            {value:'SUMMER TERM', label:'SUMMER TERM'},
                            {value:'MOCK EXAM', label:'MOCK EXAM'}
                        ]} 
                        
 onChange={handleSelect} className={errors.examterm ? 'form-control form-control-danger' : ''} name="examterm" value={examType.examterm}  />
 <span style={{color:'red'}}>{errors.examterm && errors.examterm}</span>
            </div>
     
     </section>
     </div>
     <div className="row">
     
     <div className="col-md-6">
           <div className="form-group">
         <label> Exam Status</label>
         <div className="form-radio">
                <div className="radio radio-inline"><label title='Active'>
						<input type="radio" checked={examType.status ==="Active"} onChange={handleChange} value="Active" name="status" />
										<i className="helper"></i><i className="fa fa-unlock fa-2x"></i></label></div>

                                        <div className="radio radio-inline"><label title='Inactive'>
						<input type="radio" checked={examType.status ==="Inactive"} onChange={handleChange} value="Inactive" name="status" />
										<i className="helper"></i><i className="fa fa-lock fa-2x"></i></label></div>

								</div>
            </div>
     
     </div>
     </div>
     <div  className="row">  
        <div className="col-md-2 pull-right"> 
     <button type="button" className="btn btn-primary waves-effect" onClick={handleSubmit}  > Create Exam Type</button>
     </div>


     </div> 
    </div>
                               
       </div>	
             <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12 table-responsive">
       {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={exams}  table={'tbl_exam_type'} columns={tableExamTypeHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>	</div>
       
       </div>	
			
       </div>
    
</div> :activeStep ===2 ?
<div className="row setup-content" id="assesment">
    

<div className="col-md-12">

<div className="card z-depth-0">

<div className="card-block">
<div className="card z-depth-0">


<div className="card-block">

<div className="row">
  <section className="col-md-6">
           <div className="form-group">
         <label> Exam Type<span style={{color:'red'}}>*</span></label>
         <Select  options={exams.filter(item=>item.status==='Active').map((list, id)=>{
             return {key:id, value:list.code, label:list.term, session:list.session}
         })} 
         getOptionLabel={option =>option.session + ' '+  option.label}               
 onChange={handleSelect} className={errors.assessmentterm ? 'form-control form-control-danger' : ''} name="assessmentterm" value={examType.assessmentterm} id='sessionTerm'  />
 <span style={{color:'red'}}>{errors.assessmentterm && errors.assessmentterm}</span>
            </div>
     
     </section>

     <section className="col-md-6">
         <div className="form-group">
				<label > Class</label><span style={{color:'red'}}>*</span>
				<Select  options={
                        schoolClass.map((list, idx)=> {
                            return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                            }) }
                          })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelectClass}  className={errors.assessmentClass ? 'form-control form-control-danger' : ''} name="assessmentClass" value={examType.assessmentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.assessmentClass && errors.assessmentClass}</span>
										
			</div>
     
     </section>
     
     </div>
  
     
</div></div>


    </div>

{examType.assessmentClass.length!==0 &&assessment.length!==0?
    <div className="card-block">
       <div className="card z-depth-0">

       <div className="card-block">
      
  <div className="row">
                           
       <section className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <TableExamAssessment data={assessment}  columns={tableAssesmentHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>
       </div>:''}

    




       <div className="card-block">
       <div className="card z-depth-0">

       <div className="card-block">
      
  <div className="row">
                           
       <section className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={examAssessment}  columns={tableExamAssessmentHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>
       </div>


    </div>
                               
       </div>	

       
       </div>
    
    :activeStep ===3?
<div className="row setup-content" id="extras">
		    

            

       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   
   <div className="card-block">
    
  <div className="row">
  <section className="col-md-6">
           <div className="form-group">
         <label> Exam Type<span style={{color:'red'}}>*</span></label>
         <Select  options={exams.filter(item=>item.status==='Active').map((list, id)=>{
             return {key:id, value:list.code, label:list.term, session:list.session}
         })} 
         getOptionLabel={option =>option.session + ' '+  option.label}               
 onChange={handleSelect} className={errors.sessionterm ? 'form-control form-control-danger' : ''} name="sessionterm" value={examType.sessionterm} id='sessionTerm'  />
 <span style={{color:'red'}}>{errors.sessionterm && errors.sessionterm}</span>
            </div>
     
     </section>

     <section className="col-md-6">
         <div className="form-group">
				<label > Class</label><span style={{color:'red'}}>*</span><a href="/exam/class_subject" className="pull-right" >Add subject to class</a>
       {resultSubject.length!==0? <label className='form-control'>{examType.studentClass.label}</label>:
				<Select  options={
                    schoolClass.map((list, idx)=> {
                        return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                            return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                        }) }
                      })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={examType.studentClass} id='sessionId' /> }
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
										
			</div>
     
     </section>
     
     </div>
     <div className="row">
     
     <div className="col-md-6">
           <div className="form-group">
         <label> Maximum Score<span style={{color:'red'}}>*</span></label>

         <input type="Number" value={examType.maxScore}  placeholder='Maximum Score' name='maxScore' onChange={handleChange}  className={errors.maxScore ? 'form-control form-control-danger' : 'form-control'} />
                     <span style={{color:'red'}}>{errors.maxScore && errors.maxScore}</span>

            </div>
     
     </div>
     
     <div className="col-md-6">
           <div className="form-group">
         <label> Exam Date</label><span style={{color:'red'}}>*</span>
                     <input type="date" value={examType.examDate}  placeholder='Exam Date' name='examDate' onChange={handleChange}  className={errors.examDate ? 'form-control form-control-danger' : 'form-control'} />
                     <span style={{color:'red'}}>{errors.examDate && errors.examDate}</span>
            </div>
     
     </div>

     </div>
     <div  className="row">  
        <div className="col-md-2 pull-right"> 
     <button type="button" id='btnCreate'  className="btn btn-primary waves-effect" onClick={handleSubmitClassResult}  >Create Exam Schedule</button>
     </div>


     </div> 
    </div>
                               
       </div>	

       
       </div>	
       {resultSubject.length!==0?
       <div className="card-block">
       <div className="card z-depth-0">

       <div className="card-block">
      
  <div className="row">
                           
       <section className="col-md-12 table-responsive">
       
       {notice.isDataFetching ? <TableLoader />:
   <TableExamSubject data={resultSubject.sort(sort)}  columns={tableExamSubjectHeader}  />} 
      

       </section>
               </div>
               </div>
       
       </div>
       </div>:''}



       <div className="card-block">
       <div className="card z-depth-0">

       <div className="card-block">
      
  <div className="row">
                           
       <section className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={examResult}  columns={tableExamResultHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>
       </div>

			
       </div></div>
    
</div>
:   <div className="row setup-content" id="reportSetup">
		 
{settings&&settings.map((st, idx)=>
			<div key={idx} className="col-md-12">   

<div className="card z-depth-0">

<div className="card-block">
<div className="card z-depth-0">

<div className="card-block">
        
<div className="row">
	    <section className="col-md-12">
      
<h4 className="row-seperator-header"><i className="fa fa-info-circle" ></i> Header </h4>
<table className="table table-bordered">
    <tbody>
        <tr>
            <td >
                <label >School Logo</label>
                <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.schoolLogo ==="enabled"} onChange={handleChangeSettings} value="enabled" name="schoolLogo" />
							<i className="helper"></i>Enabled</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.schoolLogo ==="disabled"} onChange={handleChangeSettings} value="disabled" name="schoolLogo" />
						<i className="helper"></i>Disabled</label></div>
    </div>			
            </td>

            <td>
                <label >Logo Align</label>
                <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.logoAlign ==="right"} onChange={handleChangeSettings} value="right" name="logoAlign" />
							<i className="helper"></i>Right</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.logoAlign ==="left"} onChange={handleChangeSettings} value="left" name="logoAlign" />
						<i className="helper"></i>Left</label></div>
    </div>
            </td>
        </tr>


        <tr>
            <td>
            <label >School Email </label>
            <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.schoolEmail ==="enabled"} onChange={handleChangeSettings} value="enabled" name="schoolEmail" />
							<i className="helper"></i>Enabled</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.schoolEmail ==="disabled"} onChange={handleChangeSettings} value="disabled" name="schoolEmail" />
						<i className="helper"></i>Disabled</label></div>
    </div>
            </td>
            <td>
            <label >Registration Number</label>
            <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.regNumber ==="enabled"} onChange={handleChangeSettings} value="enabled" name="regNumber" />
							<i className="helper"></i>enabled</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.regNumber ==="disabled"} onChange={handleChangeSettings} value="disabled" name="regNumber" />
						<i className="helper"></i>Disabled</label></div>
    </div>
            </td>
        </tr>


        <tr>
            <td>
            <label >Student Passport</label>
            <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.passport ==="enabled"} onChange={handleChangeSettings} value="enabled" name="passport" />
							<i className="helper"></i>Enabled</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.passport ==="disabled"} onChange={handleChangeSettings} value="disabled" name="passport" />
						<i className="helper"></i>Disabled</label></div>
    </div>
            </td>
            <td>
                <label >School Motto</label>
                <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.motto ==="enabled"} onChange={handleChangeSettings} value="enabled" name="motto" />
							<i className="helper"></i>Enabled</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.motto ==="disabled"} onChange={handleChangeSettings} value="disabled" name="motto" />
						<i className="helper"></i>Disabled</label></div>
    </div>
            </td>
        </tr>

        <tr>
            <td >
            <div className="form-group">
                        <label >Result Heading</label>
                            <input type="text" onChange={handleChangeSettings} name="resultTitle"   placeholder="Result Heading" value={st.resultTitle} className="form-control" />
                        
                    </div>
            </td>
            <td>
            <label >School Address</label>
            <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.schoolAddress ==="enabled"} onChange={handleChangeSettings} value="enabled" name="schoolAddress" />
							<i className="helper"></i>Enabled</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.schoolAddress ==="disabled"} onChange={handleChangeSettings} value="disabled" name="schoolAddress" />
						<i className="helper"></i>Disabled</label></div>
    </div>		
            </td>
        </tr>
    </tbody>
</table>
    </section>
					
	    </div></div></div>

    
     <div className="card z-depth-0">
    <div className="card-block">
              
        <div className="row">
	    <section className="col-md-12">
      
<h4 className="row-seperator-header"><i className="fa fa-info-circle" ></i> Result Area </h4>
<table className="table table-bordered">
    <tbody>
        <tr>
            <td >
                <label >Show Subject</label>
                <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.showSubject ==="Name"} onChange={handleChangeSettings} value="Name" name="showSubject" />
							<i className="helper"></i>Subject Name</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.showSubject ==="Abbreviation"} onChange={handleChangeSettings} value="Abbreviation" name="showSubject" />
						<i className="helper"></i>Subject Abbreviation</label></div>
    </div>
			
            </td>
            <td>
            <label >Show Subject Position</label>
            
            <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.subjectPosition ==="enabled"} onChange={handleChangeSettings} value="enabled" name="subjectPosition" />
							<i className="helper"></i>Enabled</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.subjectPosition==="disabled"} onChange={handleChangeSettings} value="Disabled" name="subjectPosition" />
						<i className="helper"></i>Disabled</label></div>
    </div>
            </td>
        </tr>
        <tr>
            <td>
            <label >Show  Grade </label>
            <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.grade ==="enabled"} onChange={handleChangeSettings} value="enabled" name="grade" />
							<i className="helper"></i>Enabled</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.grade ==="disabled"} onChange={handleChangeSettings} value="disabled" name="grade" />
						<i className="helper"></i>Disabled</label></div>
    </div>
            </td>
            <td>
            <label >Show Grading Scale</label>
            <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.gradeScale ==="enabled"} onChange={handleChangeSettings} value="enabled" name="gradeScale" />
							<i className="helper"></i>Enabled</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.gradeScale ==="disabled"} onChange={handleChangeSettings} value="disabled" name="gradeScale" />
						<i className="helper"></i>Disabled</label></div>
    </div>
            </td>
        </tr>
        <tr>
            <td>
            <label >Show Attendance</label>
            <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.attendance ==="enabled"} onChange={handleChangeSettings} value="enabled" name="attendance" />
							<i className="helper"></i>Enabled</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.attendance ==="disabled"} onChange={handleChangeSettings} value="disabled" name="attendance" />
						<i className="helper"></i>Disabled</label></div>
    </div>
            </td>
            <td>
                <label >Show Remark</label>
                <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.remark ==="enabled"} onChange={handleChangeSettings} value="enabled" name="remark" />
							<i className="helper"></i>Enabled</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.remark ==="disabled"} onChange={handleChangeSettings} value="disabled" name="remark" />
						<i className="helper"></i>Disabled</label></div>
    </div>
            </td>
        </tr>
        <tr>
        <td>
            <label >Calculate Average</label>
            <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.average ==="enabled"} onChange={handleChangeSettings} value="enabled" name="average" />
							<i className="helper"></i>Enabled</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.average ==="disabled"} onChange={handleChangeSettings} value="disabled" name="average" />
						<i className="helper"></i>Disabled</label></div>
    </div>
            </td>
            <td>
                <label >Show Max Marks</label>
                <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.maxMark ==="enabled"} onChange={handleChangeSettings} value="enabled" name="maxMark" />
							<i className="helper"></i>Enabled</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.maxMark ==="disabled"} onChange={handleChangeSettings} value="disabled" name="maxMark" />
						<i className="helper"></i>Disabled</label></div>
    </div>
            </td>
        </tr>
    </tbody>
</table>
    </section>
					
	    </div></div></div>
    
    
        <div className="card z-depth-0">
	    <div className="card-block">

        
        <h4 className="row-seperator-header "><i className="fa fa-info-circle" ></i> Standards Report Card</h4>
        

        <table className="table table-bordered">
            <tbody>
                <tr>
                    <td colSpan="2">
                        <section className="col col-6">
                            <label className="label " >Show Report</label>
                            <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.assessment ==="enabled"} onChange={handleChangeSettings} value="enabled" name="assessment" />
							<i className="helper"></i>Enabled</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.assessment ==="disabled"} onChange={handleChangeSettings} value="disabled" name="assessment" />
						<i className="helper"></i>Disabled</label></div>
    </div>
                        </section>
                    </td>
                </tr>
            </tbody>
        </table>
     </div></div>

    


        <div className="card z-depth-0">
    <div className="card-block">
              
        <div className="row">
	    <section className="col-md-12">
      
        


<h4 className="row-seperator-header"><i className="fa fa-info-circle" ></i> Footer </h4>
<table className="table table-bordered">
    <tbody>

    <tr>        
<td >
        
            <div className="form-group">
                <label className="input"> Principal</label>
                <Select  options={staff&&staff.map((list, idx)=> {
                                   return {key:idx, value: list.code, label:list.staffName, fileNo:list.fileNo }
                                 })
                            }      
    onChange={handleChangeSelect}  name="headofSchool" value={{value:st.headofSchool, label:getStaffField(st.headofSchool, 'staffName')} }  /> 
                
            </div>
        
    </td>


    <td>
   <div className="form-group">
                <label className="input"> Head Teacher</label>
                <Select  options={staff&&staff.map((list, idx)=> {
                                   return {key:idx, value: list.code, label:list.staffName, fileNo:list.fileNo }
                                 })
                            }       
    onChange={handleChangeSelect}  name="headofPrimary" value={{value:st.headofPrimary, label:getStaffField(st.headofPrimary, 'staffName')} }  /> 
                
            </div>
    </td>
</tr>



        <tr>


        <td >
                
        <label >Principal's Name & Signature </label>
            <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.principalSign ==="enabled"} onChange={handleChangeSettings} value="enabled" name="principalSign" />
							<i className="helper"></i>Enabled</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.principalSign==="disabled"} onChange={handleChangeSettings} value="disabled" name="principalSign" />
						<i className="helper"></i>Disabled</label></div>
    </div>
                
            </td>


            <td>

            <label >Head Teacher's Name & Signature </label>
            <div className="form-radio">
                <div className="radio radio-inline"><label>
	<input type="radio" checked={st.teacherSign ==="enabled"} onChange={handleChangeSettings} value="enabled" name="teacherSign" />
							<i className="helper"></i>Enabled</label></div>

    <div className="radio radio-inline"><label>
	<input type="radio" checked={st.teacherSign==="disabled"} onChange={handleChangeSettings} value="disabled" name="teacherSign" />
						<i className="helper"></i>Disabled</label></div>
    </div>



          
            </td>
        </tr>
        
    </tbody>
</table>
    </section>
					
	    </div>
                        </div>
                        
</div>	

	</div>

</div>	
     
</div>)}

</div>}

     <button onClick={activeStep === steps.length ? handleSubmitResultSettings: handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Update Setup </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>
                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ResultSetup) 