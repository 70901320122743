import React, {useState, useEffect, useRef} from 'react'
import {Alerts} from '../component/notify'
import {ServerUrl, ImagesUrl, config, mainUrl} from '../component/include'
import axios from 'axios'
import Cookies from 'js-cookie'
import Preloader from '../component/preloader'
import { LoadEffect } from '../component/loader'

 const ForgotPassword =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [user, setUser] = useState({
		email:'',
		rsMessage:''
    })
    const [errors, setErrors] = useState({});

    const handleChange = event =>{		
        let {name, value} = event.target;	
       setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }
    const handleSubmit = event =>{
        event.preventDefault();
         
    setErrors(ValidateInput(user));
        setIsSubmitting(true);
    }
    const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        
        if(!email){
            errors.email ='Please enter valid email address';
        }
        
        return errors;
        }
        useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
            submit()
            }
            },[errors])

        	
	function submit(){  
		setNotice({...notice,  isLoading: true}) 	
           
        let formData = user;
        const fd = new FormData();
        for(let k in formData){
          fd.append(k, formData[k])
        }
		let url = ServerUrl+'/save_controller.php?tablename=tbl_reset_password';
		axios.post(url, fd, config)
		.then(response =>{
	    if (response.data.type ==='success'){
			setUser({...user, rsMessage:response.data.message, email:''})
			
			}else{

				Alerts('Error!', 'danger', JSON.stringify(response.data))
			}    
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
			 
		}).finally(()=>{
            setIsSubmitting(false)
            setNotice({...notice,  isLoading: false }) 
        }) 
		
}

const clearCookies=()=>{
	Cookies.remove('gaatkaustnm')
	 Cookies.remove('gaatkauscd')	
  Cookies.remove('gaatkagpcd')
  Cookies.remove('gaatkamtk')
  Cookies.remove('gaatkarfsh')
  localStorage.setItem('_gaatexpt', Date.now())
  localStorage.setItem('_gaatbexpt', Date.now())
  localStorage.setItem('_gaatmnulk', Date.now())
  localStorage.setItem('gaatkarls', Date.now())
}


useEffect(()=>{  
  clearCookies()
  
},[]);


        return (<>
          <Preloader />

		  {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
		  <section style={{background: `url(${ImagesUrl}/background/f.jpeg) `, backgroundSize:'100%'}}  
		  className="login-block">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    
	<form className="md-float-material form-material">
		
	<div className="auth-box card">
		<div className="card-block">
			<div className="row m-b-20">
			
				<div className="col-md-12">
				<div className="text-center">
		
		<img src={ImagesUrl+'/about/logo.png'} className="img-100" alt="logo" />
		</div>
				

					<h3 className="text-center">Recover your password</h3>
				</div>

				{user.rsMessage.length!==0?
				<div className='alert alert-success'>{user.rsMessage}</div>:''}

			</div>
			<div className="form-group form-primary">

				<input type="email" id="email" name="email"  value={user.email} placeholder="Your Email Address" onChange={handleChange} className={errors.email  ? 'form-control formerror' : 'form-control'} /> 
				<span className=" form-bar error">{ errors.email}</span>

			</div>
			<div className="row m-t-30">
				<div className="col-md-12">
					<button type="button" onClick={handleSubmit} className="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20">Reset Password</button>
				</div>
			</div>
            <p className="f-w-600 text-right">Back to <a href="/">Login.</a></p>
			<div className="row">
				<div className="col-md-10">
					<p className="text-inverse text-left m-b-0">Thank you.</p>
					<p className="text-inverse text-left"><a href="https://www.kayus.com.ng" target={'_blank'}><b className="f-w-600">Powered By KAYUS</b></a></p>
				</div>
				<div className="col-md-2">
					
				<img src={ImagesUrl+'/about/kayus.png'} alt="KAYUS" className="img-30" />
				</div>
			</div>
		</div>
                            </div>
                        </form>
                </div>
            </div>
        </div>
    </section>



         
</> 

 );
        
}

export default React.memo(ForgotPassword) 