import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import {Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl,  Token,  config} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, getAge, FormatNumber, getRank, getResultHead, getCAPoint, getPosition} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import Error403 from '../settings/error403'
import { useSelector } from 'react-redux'



const PrintMidTermResult =()=> {
    const [errors, setErrors] = useState({});
    const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [examtype, setExamtype]=useState([])
    
 const [allStudents, setAllStudents] = useState([]);
    const [disabledResult, setDisabledResult]=useState([])
    const [classTeacher, setClassTeacher] = useState([]);
    const [settings, setSettings]=useState([])
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo[0]);

const [remarkResults, setRemarkResults] = useState([])
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const staff  = useSelector(state => state.rootReducer.staff);
 const [student, setStudent] = useState([]);
 const [studentList, setStudentList] = useState([]);
const [sqlQuery, setSqlQuery] = useState([]);  
const [remark, setRemark]=useState([])
const [assessment, setAssessment]=useState([])
const [house, setHouse]=useState([])
const [grade, setGrade] = useState([])
const [grademark, setGrademark]=useState([])
    const [scores, setScores]=useState([])
    
    const [isSubmitting, setIsSubmitting] = useState(false);

    
    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        student:[],
        resultHead:'Secondary',
        obtained:0,
        average:0,
        orderBy:[]
    })

    const [signature, setSignature] = useState({});

    const [atResult, setAtResult]=useState({
        attendanceStart:'',
        attendanceEnd:''
    })

    

    const handleSearch = event =>{
        event.preventDefault(); 
        
        let errors = {};   
        if(result.studentClass.length===0){
            errors.studentClass ='Please select class';
        }   
        if(result.examtype.length===0){
            errors.examtype ='Please select subject';
        } 
        if(result.orderBy.length===0){
            errors.orderBy ='Please order of result';
        } 

        setErrors(errors)

        if(Object.keys(errors).length === 0){
            fetchResult()
            }

    }  


        const fetchSettings =()=>{
            const fd = new FormData();
        fd.append('jwt', Token);
            let url = ServerUrl + '/fetchField/tbl_result_slip/settings/ID/1';
            axios.post(url, fd, config).then(result=>{
                setSettings(result.data[0])
              
            })
        }
        const fetchExamType =()=>{
            var sql ="SELECT CONCAT(t.session, ' ', t.term) as exam, r.examCode  from tbl_exam_result r, tbl_exam_type t where r.examCode = t.code and t.status='Active' and t.term <> 'MOCK EXAM'  group by r.examCode order by exam ASC" ;
    
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        setExamtype(result.data)
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }

   
    
    const fetchRemark =()=>{
        var sql ="SELECT code, catTeacherRemark, studentCode, headofSchool, teacherName, catHeadTeacherRemark, teacherName, headName, resultHead, studentCode from tbl_result_remarks where examType = '"+result.examtype.value+"' and sectionID = '"+result.studentClass.value+"'" ;

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token);
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{
    if(response.data.length!==0){
        setRemarkResults(response.data)
    }else{
        setRemarkResults([])
    }
    
})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}


const handleSelectStudent =(option, action)=>{     
    setResult({...result, [action.name]:option});
}

    const handleSelect =(option, action)=>{     
          
       
        setScores([])

        setErrors({...errors, [action.name]:''})
        if(action.name==='studentClass'){
            setResult({...result, [action.name]:option, orderBy:[]});
            fetchClassSubject(option.value, result.examtype.value)
            fetchClassTeaher(option.classID, option.value)
            fetchGradeMark(option.classtype)

            fetchStudentList(option.value, result.examtype.value)
        }else if(action.name==='examtype'){
            setResult({...result, [action.name]:option, studentClass:[], student:[], orderBy:[]});
            
        fetchStudentList(result.studentClass.value, option.value)
            fetchClassSubject(result.studentClass.value, option.value)
            fetchExamAttendance(option.value)
        }else{
            setResult({...result, [action.name]:option});
        }
    }




    const fetchStatistics =()=>{
        setNotice({...notice, isLoading: true}) 

     const fd = new FormData();
    fd.append("sql", sqlQuery);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
         if(result.data.length!==0){
setStudent(result.data)
        }else{
            setStudent([])
        }  
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
        
    }) 
    }

    
    const fetchDisbaledStudent =(section, exam)=>{
        
        var sql ="SELECT studentList from  tbl_disable_result where  examType ='"+exam+"' and sectionID ='"+section+"' Limit 1";
    
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
    
    if(result.data.length!==0 && Array.isArray(result.data)){
        const options = JSON.parse(result.data[0].studentList)
    
        if(options.length!==0){
            const rs = options.map((item)=>item.studentCode)
    setDisabledResult(rs)
        }else{
            
    setDisabledResult([])
        }
    
    }
    
    })
    
    }

    const handleSelectType =(option, action)=>{  
        
         
        setResult({...result, [action.name]:option});
        setErrors({...errors, [action.name]:''})
        fetchStatistics()  
        fetchRemark()

    }


        const fetchStudentList =(section, exam)=>{
            
            fetchDisbaledStudent(section, exam)
            setNotice({...notice, isLoading: true}) 
            var sql ="SELECT s.passport, s.code, s.gender, s.admissionNumber, s.house, s.dateOfBirth, s.registerNumber, s.studentName, e.examCode from tbl_students s, tbl_exam_result e where s.code = e.studentCode and e.examCode ='"+exam+"' and s.termination <> 'Yes' and  e.sectionID ='"+section+"' group by s.code"
        
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
            if(result.data.length!==0){
                setStudentList(result.data)
            }else{
        setStudentList([])
            }
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
           
        })
        }



        const fetchClassSubject =(sectionID, exam)=>{

            var sql ="SELECT s.subjectName, s.abbreviation, s.code, r.examCode from tbl_exam_result r, tbl_subject s where r.subjectCode = s.code and r.examCode ='"+exam+"' and r.sectionID ='"+sectionID+"' group by r.subjectCode  order by r.displayOrder ASC" ;
    
            const fd = new FormData();
            fd.append("sql", sql);
            fd.append('jwt', Token);
            let url = ServerUrl+'/fetchBySql_controller';
            axios.post(url, fd, config).then(response=>{
                if(response.data.length!==0){
                var subj = response.data;
    

                //use this to get avearege

                
                let sqlString = subj.map(data=>"sum(case when e.subjectCode = '"+data.code+"'  then e.catScore else 0 end) AS '"+data.subjectName+"'")
                   
             
             
             let final =   'SELECT  rank() over (order by  CAST(sum(e.catScore) AS decimal(10,2)) desc) position,  cast(sum(case when e.catScore >0 and e.catAbsent="No" then  e.catScore else 0 end)/sum(case when e.catScore >0 and e.catAbsent="No" then 1 else 0 end) as decimal(10,2)) as average, s.code, s.passport, s.gender, s.house, s.dateOfBirth, s.admissionNumber, s.registerNumber, s.studentName, sum(case when e.catScore >0 and e.catAbsent="No" then e.catScore else 0 end) as obtained,  sum(case when e.catScore >0 and e.catAbsent="No" then 100 else 0 end) as obtainable, e.maxMark, e.catAbsent, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and  e.examCode ="'+exam+'" and e.sectionID ="'+sectionID+'" group by e.studentCode '


            setSqlQuery(final)
               
            }else{
                setSqlQuery([])
            }
            })

        }

       
      

        
        const fetchGradeRemark =()=>{
            const fd = new FormData();
            fd.append('jwt', Token);
            let url = ServerUrl + '/fetchField/tbl_exam_settings/gradeRemark/ID/1';
            axios.post(url, fd, config)
            .then(result =>setRemark(result.data.sort((a, b) =>(a.remarkName > b.remarkName) ? 1 : -1)))
        }
        
        
const getRemark =(code)=>{
    const ans = remark.filter(item=>item.code===code)
    return ans[0].remarkName
    }

const getGrade =(score, field)=>{
    const ans = grademark.filter(itm=>parseFloat(score) >= parseFloat(itm.minimum) && parseFloat(score) <= parseFloat(itm.maximum));
    const remark = ans.length!==0? getRemark(ans[0].gradeMark):''
    const grade = ans.length!==0? getGradeScore(ans[0].gradeCode):''
   
return field ==='grade'?String(grade):String(remark)
}


        const fetchResult =()=>{
            setNotice({...notice, isLoading: true}) 

            var sql ="SELECT s.subjectName, s.abbreviation, e.catPosition, e.absent, e.studentCode, e.position, e.catScore, e.catAverage, e.catComment, e.catAbsent, e.catRemark, e.catGrade, e.teacher from tbl_exam_result e, tbl_subject s where e.subjectCode = s.code and e.examCode ='"+result.examtype.value+"' and e.sectionID ='"+result.studentClass.value+"'  order by e.displayOrder ASC" ;


         const fd = new FormData();
        fd.append("sql", sql);
            fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(response=>{
             if(response.data.length!==0){

                var rs = response.data;
    var list = [];
    for (var i = 0; i< rs.length;  i++) {


        list.push(

            { 
                abbreviation:rs[i].abbreviation,
                absent:rs[i].catAbsent,
                comment:rs[i].catComment,
                studentCode:rs[i].studentCode,
                position:rs[i].catPosition,
                catAverage:rs[i].catAverage,
                catScore:rs[i].catScore,
                grade:getGrade(rs[i].catScore, 'grade'),
                remark:getGrade(rs[i].catScore, 'remark'),
                subjectName:rs[i].subjectName,
                teacher:rs[i].teacher,
            });
            }

        setScores(list)
        if(result.student.length!==0 && studentList.length!==0){
            setAllStudents(studentList.filter(item=>item.code===result.student.value))
        }else{
            setAllStudents(studentList)
        }

        window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
            }else{
                setScores([])
                setResult({...result, obtained:0, average:0})
                Alerts('Info!', 'info', 'There are no schedule found for the selected exam')
            }  
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
            
        }) 
        }

        const fetchGrade =()=>{
            const fd = new FormData();
            fd.append('jwt', Token);
            let url = ServerUrl + '/fetchField/tbl_exam_settings/grade/ID/1';
            axios.post(url, fd, config)
            .then(result =>setGrade(result.data))
        }
        






        const fetchGradeMark =(classType)=>{
            const fd = new FormData();
            fd.append('jwt', Token);
            let url = ServerUrl + '/fetchField/tbl_exam_settings/gradeMark/ID/1';
            axios.post(url, fd, config)
            .then(result =>{

                if(result.data.length!==0){
                    var grade = result.data.filter(item=>item.classtype===classType)
                    setGrademark(grade.sort((a, b) =>(parseFloat(b.maximum) > parseFloat(a.maximum)) ? 1 : -1))
                }else{
                    setGrademark([])
                }
            })             
        }


        const getGradeScore =(code)=>{
            const result = grade&&grade.filter(item=>item.code===code)
            return result.length!==0?result[0].gradeName:''
            }
        


            const fetchExamAttendance =(value)=>{
                let sql ='select startDate, endDate from tbl_result_attendance where exam = "'+value+'"'
        
                const fd = new FormData();
                fd.append('jwt', Token); 
                fd.append("sql", sql);
                let url = ServerUrl+'/fetchBySql_controller'
                axios.post(url, fd, config).then(response=>{
                    if(Array.isArray(response.data)&& response.data.length!==0){
                        setAtResult({...atResult, attendanceStart:response.data[0].startDate, attendanceEnd:response.data[0].endDate  })
                       
                    }else{
                        setAtResult({...atResult, attendanceStart:'', attendanceEnd:''  })
                    } 
                })
                .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
        
            })
        }

           

        const fetchHouse =()=>{
            const fd = new FormData();
            fd.append("jwt", Token);
            let url = ServerUrl + '/fetchField/tbl_student_master/house/ID/1';
            axios.post(url, fd, config)
            .then(result =>setHouse(result.data))
        }

      


            const handleStaffSignature=(cell)=>{
                if(cell!=='[]' && cell!=''){
                return JSON.parse(cell).map((itm, idx)=>{
                    return <img id="viewPassport2" key={idx} className="img-100" style={{ maxHeight:'50px' }}  src={`${ImagesUrl}/staff/signature/${getStaffField(itm, 'signature')}`}   alt='' />
                } );
            }else{
                return ''
            } 
            }



            const fetchClassTeaher =(classID, sectionID)=>{
                var newStaff = staff&&staff.filter((item=>item.classID===classID&&item.sectionID===sectionID))
                setClassTeacher(newStaff)
            }

       



     const handleStaffName=(cell)=>{
        if(cell!=='[]' && cell!=''){
        return JSON.parse(cell).map((itm, idx)=>{
            return <span  key={idx}> {' '+getStaffField(itm, 'staffName')}</span>
        } );
    }else{
        return ''
    } 
    }




       const getStaffField = (code, field) =>{   
    
        const result = staff&&staff.filter(list=>list.code===code);
        const answer = result.map((c)=>c[field] ); 
        return  String(answer);
       }


       
const getScores =(code)=>{
    let scr =  [...scores];
    const rs = scr.filter(item=>item.studentCode===code)
    return rs.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1);

}

const getStudent =(code)=>{
    let scr =  [...student];
    const rs = scr.filter(item=>item.code===code)
    return rs.length!==0?rs[0]:{obtainable:0, obtained:0, average:0};

}




const getData =(code)=>{
     let scr =  [...remarkResults];
    const rs = scr.filter(item=>item.studentCode===code)
    return rs.length!==0?rs[0]:{ code:'', catTeacherRemark:'', studentCode:'', headofSchool:'', teacherName:'', catHeadTeacherRemark:'', teacherName:'', headName:'', resultHead:'', studentCode:'' }

} 




useEffect(()=>{
  // setIsAuthenticated(verifyRouter(location.pathname))
    fetchHouse()
    fetchSettings()
    fetchGrade()
    fetchGradeRemark()
    fetchExamType()
   // fetchAssessment()
},[]);



        return ( 
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
               
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Mid Term Result">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Mid Term Result</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row no-print ">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Student Mid Term Result</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		
        <div className="row">
        <section className="col-md-6">
     <div className="form-group">
          <label> Exam Type <span style={{color:'red'}}>*</span></label>
          <Select  options={examtype&&examtype.map((list, idx)=> {
                      return {key:idx, value: list.examCode, label: list.exam, term: list.term }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

 
             </div>
     
     </section>

     

                    <section className="col-md-6">
                    <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label>
         <Select  options={
                          schoolClass.map((list, idx)=> {
                               return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
            </div>
     
     </section>       
   
     <section className="col-md-6">
           <div className="form-group">
         <label> Order By <span style={{color:'red'}}>*</span></label>
         <Select  options={[
             {value:'order by s.studentName ASC', label:'Order by Name'},
                    { value: 'order by s.registerNumber ASC', label: 'Order By Register Number'},
             { value: 'order by s.admissionNumber ASC', label: 'Order By Admission Number'},
                ]} 
onChange={handleSelectType} className={errors.orderBy ? 'form-control form-control-danger' : ''} name="orderBy" value={result.orderBy}  /> 
<span style={{color:'red'}}>{errors.orderBy && errors.orderBy}</span>

            </div>
     
     </section>

     
     <section className="col-md-6">
     <div className="form-group">
						<label>Student</label>
						<Select  options={
                           studentList&&studentList.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.admissionNumber, studentName:list.studentName }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName}

onChange={handleSelectStudent} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={result.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>
                                            </section> 
				</div>	


	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search Result</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	
{getResultHead(result.studentClass.classtype)!=='Pre-Nursery'?
       <div>
{scores.length!==0?allStudents.map((std, index)=>

<div className="card z-depth-0" key={index}>
		<div className="card-block">
	
                       <div className="row" >

<table className="table  table-full-width">
       <tbody>
       <tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${schoolInfo.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td className="text-center" >
                        

                 
                        <h4 style={{textAlign:'center'}}><b>{schoolInfo.schoolName}</b></h4>
                        <h6 style={{textAlign:'center'}}><b>Motto: {schoolInfo.schoolMotto}</b></h6>
                        <div style={{textAlign:'center'}}>  {schoolInfo.address}  </div>
                        <div style={{textAlign:'center'}}> {schoolInfo.mobile}, {schoolInfo.altMobile}, {schoolInfo.email} </div>
                        
                        <div style={{textAlign:'center'}}>  Website: {schoolInfo.website}  </div>
                             </td>

 <td > <img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  /></td>
                </tr>
            </tbody>
       </table>

</div> 

 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>HALF TERM REPORT {result.examtype.label} </b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
       <tr>
                    <td colSpan={2}>NAME: <b>{std.studentName.toUpperCase()}</b></td>
                    
                    
                 <td >ADMISSION NUMBER: <b> {std.admissionNumber} </b></td>
                    
                   
              
                </tr>

                <tr>
                    
                <td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>
                <td >CLASS: <b> {result.studentClass.label} </b></td>
                <td >NO. IN CLASS: <b>{studentList.length}</b></td>
               
                </tr>

                <tr>
                    
                <td >MARKS OBTAINED: <b>{getStudent(std.code).obtained && parseFloat(getStudent(std.code).obtained).toFixed(2)} </b></td>
                    
                    <td >OUT OF: <b>{getStudent(std.code).obtainable}</b></td>
                
                <td >STUDENT'S AVERAGE: <b> {getStudent(std.code).average && parseFloat(getStudent(std.code).average).toFixed(2)}   </b></td>
               
                </tr> 
                

                

            </tbody>
       </table></div>
       <div className="col-md-12 table-responsive">
        <table className="table table-striped table-bordered">
        

            <thead>
                <tr>
                    <th >Subjects</th>
                    <th >CAT SCORE(100%)</th>
                    <th >SUBJ AVERAGE</th>
                    
                    {getPosition(result.studentClass.classtype)==='Position'? <th >SUBJ POSITION</th>:''}
                    <th >GRADE</th>
                    <th >REMARK</th>
                </tr>
            </thead>
            <tbody>
                
            {getScores(std.code).map((st, idx)=> st.absent!=='Yes' && parseFloat(st.catScore) >0 ?
                <tr key={idx}>
                
                    <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.subjectName.toUpperCase()}</td>
                    <td>{st.catScore}</td>
                    <td>{FormatNumber(st.catAverage)}</td>
                    
                    {getPosition(result.studentClass.classtype)==='Position'? <td>{st.position}<sup>{getRank(st.position)}</sup></td>:''}
                    
                    <td>{st.grade}</td>
                    <td>{st.remark}</td>
                
            </tr>
                :[])} 
            </tbody> 
        </table>
 </div>	 
 <div className="col-md-12 table-responsive">
 <table className='table table-bordered '>
         <tbody>
             <tr>
             <td><b >KEY TO GRADINGS</b></td>
               
             </tr>
             <tr>
                 <td><b>{grademark.map((data, index)=><i key={index}>{getGradeScore(data.gradeCode) + ' =' +data.minimum + ' - ' + data.maximum+ ' [' + '  ' +getRemark(data.gradeMark) + '],  '}</i>)} </b></td>
                 
             </tr>
         </tbody>
     </table>
       
<table className='table table-bordered'>
        <tbody>
            <tr>
                <td  style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>CLASS TEACHER’S COMMENT(S) </b></td>
                <td colSpan={2} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).catTeacherRemark} </td>
                
            </tr>
            <tr>
            <td  style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>CLASS TEACHER’SNAME/SIGNATURE </b></td>

            <td style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}</td>
            <td style={{maxWidth:'5px' }}> {handleStaffSignature(getData(std.code).teacherName)}</td>
                
            </tr>
           
        </tbody>
    </table>
 </div>
 </div> 
 
 <div className="row no-print">
        <div className="col-md-12 pull-right">
        <form method='POST' action={ServerUrl+'/printout/midterm_result.php?token='+userToken} target='_blank'>
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                <input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 
                <input type='hidden' name='totalStudent' value ={studentList.length} />
                <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='studentList' value ={JSON.stringify(allStudents, null, 2)} />
                <input type='hidden' name='resultHead' value ={getPosition(result.studentClass.classtype)} />
                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} />
                <input type='hidden' name='attendance' value ={JSON.stringify(result, null, 2)} />
               
                <input type='hidden' name='jwtToken' value ={Token} />
               

        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>


 
 {disabledResult&&disabledResult.includes(std.code)? 
<div style={{ 

height:'100%',
backgroundColor:'#E1E1E1',
width:'100%',
alignItems:'center',
justifyContent:'center',
flexDirection:'column',
display:'flex',
flex:1,
position:'absolute',
padding:'20px',
opacity:0.98


 }}>


<h3 style={{ color:'blue',  }}> Result not available at the momemt, please contact your child's class teacher</h3>

</div>
:''} 

        </div>):''}
        </div>
        :
        <div>
            
{scores.length!==0?allStudents.map((std, index)=> <div key={index} className="card z-depth-0">
	
    <div className="card-block">
          
<div  className="row">

<table className="table  table-full-width ">
<tbody>
<tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${schoolInfo.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td className="text-center" >
                        

                 
                        <h4 style={{textAlign:'center'}}><b>{schoolInfo.schoolName}</b></h4>
                        <h6 style={{textAlign:'center'}}><b>Motto: {schoolInfo.schoolMotto}</b></h6>
                        <div style={{textAlign:'center'}}>  {schoolInfo.address}  </div>
                        <div style={{textAlign:'center'}}> {schoolInfo.mobile}, {schoolInfo.altMobile}, {schoolInfo.email} </div>
                        
                        <div style={{textAlign:'center'}}>  Website: {schoolInfo.website}  </div>
                             </td>

 <td > <img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  /></td>
                </tr>
     </tbody>
</table>

</div> 

<div className="row">
 <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>HALF TERM REPORT {result.examtype.label} </b></u></h5>
 </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

<table className="table table-bordered  table-full-width">
<tbody>
<tr>
                    <td colSpan={2}>NAME: <b>{std.studentName.toUpperCase()}</b></td>
                    
                    
                 <td >ADMISSION NUMBER: <b> {std.admissionNumber} </b></td>
                    
                   
              
                </tr>

                <tr>
                    
                <td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>
                <td >CLASS: <b> {result.studentClass.label} </b></td>
                <td >NO. IN CLASS: <b>{studentList.length}</b></td>
               
                </tr>

         

     </tbody>
</table></div>
<div className="col-md-12 table-responsive">
 <table className="table table-striped table-bordered">
         
 {getScores(std.code).map((st, idx)=> st.absent!=='Yes'?
          <tbody key={idx}>
         <tr >
         
             <td style={{textAlign:'center' }}> <b>{st.subjectName.toUpperCase()}</b></td></tr>

             <tr>
             <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.comment}</td>
         
     </tr>
     
     
     </tbody>
         :[])} 
 </table>
</div>	 
<div className="col-md-12">
<table className='table table-bordered'>
        <tbody>
            <tr>
                <td  style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>CLASS TEACHER’S COMMENT(S) </b></td>
                <td colSpan={2} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).catTeacherRemark} </td>
                
            </tr>
            <tr>
            <td  style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>CLASS TEACHER’SNAME/SIGNATURE </b></td>

            <td style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}</td>
            <td style={{maxWidth:'5px' }}> {handleStaffSignature(getData(std.code).teacherName)}</td>
                
            </tr>
           
        </tbody>
    </table>


</div>
</div> 
<div className="row no-print">
 <div className="col-md-12 pull-right">
 <form method='POST' action={ServerUrl+'/printout/midterm_result_pre.php?token='+userToken} target='_blank'>


        

                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                <input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 
                <input type='hidden' name='totalStudent' value ={studentList.length} />
                <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='studentList' value ={JSON.stringify(allStudents, null, 2)} />
                <input type='hidden' name='resultHead' value ={getPosition(result.studentClass.classtype)} />
                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} />
                <input type='hidden' name='attendance' value ={JSON.stringify(result, null, 2)} />
               
                <input type='hidden' name='jwtToken' value ={Token} />
               

        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
 </div>
 
 
 
 </div>
 
 {disabledResult&&disabledResult.includes(std.code)? 
<div style={{ 

height:'100%',
backgroundColor:'#E1E1E1',
width:'100%',
alignItems:'center',
justifyContent:'center',
flexDirection:'column',
display:'flex',
flex:1,
position:'absolute',
padding:'20px',
opacity:0.98


 }}>


<h3 style={{ color:'blue',  }}> Result not available at the momemt, please contact your child's class teacher</h3>

</div>
:''} 
 </div>


 </div>):''}
 
 </div>}
 
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(PrintMidTermResult) 