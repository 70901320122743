import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')


export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
const APP_KEY = 'gaatschool';

export const staffName = Cookies.get('gaatkaustnm')?CryptoJS.AES.decrypt(Cookies.get('gaatkaustnm'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'
export const staffCode = Cookies.get('gaatkauscd')?CryptoJS.AES.decrypt(Cookies.get('gaatkauscd'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'

export const groupCode = Cookies.get('gaatkagpcd')?CryptoJS.AES.decrypt(Cookies.get('gaatkagpcd'), APP_KEY).toString(CryptoJS.enc.Utf8):'null' 

export const Token = Cookies.get('gaatkamtk')?CryptoJS.AES.decrypt(Cookies.get('gaatkamtk'), APP_KEY).toString(CryptoJS.enc.Utf8):undefined
export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 
export const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

//school name and LocalUrl should be entered manually
export const schoolName = 'GAAT INTERNATIONAL SCHOOL'
export const Currency = '₦'
export  const smsAccountUrl = 'https://www.multitexter.com';  


export const allowEdit = 'Yes'
export const allowDelete = 'Yes'

//export  const ServerUrl = 'http://192.168.64.3/kayus/gaat/controller';
//export  const ImagesUrl = 'http://192.168.64.3/kayus/gaat/images'; 


export  const ServerUrl = 'https://app.gaatschool.com/controller';
export  const ImagesUrl = 'https://app.gaatschool.com/images'; 

export  const mainUrl = 'https://sms.gaatschool.com'; 
//export const LocalUrl ='app.gaatschool.com'

export const LocalUrl ='localhost'

export  const ParentUrl= 'https://parent.gaatschool.com'; 
export  const teacherUrl = 'https://staff.gaatschool.com'; 
export  const studentUrl = 'https://learners.gaatschool.com';  
export const DatabaseName= 'gaatbtnv_gaat_db' 

export  const API_URL= 'https://api.gaatschool.com/api'; 