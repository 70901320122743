import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config} from '../component/include'
import {Type} from 'react-bootstrap-table2-editor';
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { userToken, verifyRouter } from '../component/authentication'
import { useDispatch, useSelector } from 'react-redux'
import {RadioButton} from '../component/formControl'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { generateCardNo } from '../component/globalFunction'


 const PortalPin =()=>{
   

    const [total, setTotal] = useState('');


    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [portalPin, setPortalPin]= useState([])
    const student  = useSelector(state => state.rootReducer.students);
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [errors, setErrors] = useState({});

   
    const handleChange = event =>{
		const {name, value} = event.target
       setTotal(value)
	   setErrors({...errors, total:''})
	}
         const handleResetPIN =(code)=>{
           
            setNotice({...notice, isLoading: true}) 
            let close =   document.getElementById('btnWarningDialog-'+code)
            close.click();

        var sql ="Delete from tbl_portal_PIN where code='"+code+"'"        
        
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(response=>{
        window.location.reload()
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
            
        }) 

         }

       
const DeleteActive =(cell)=>{
    setNotice({...notice, isLoading: true}) 
    let close =   document.getElementById('btnWarningDialog-'+cell)
    close.click();
var sql ="Delete from  tbl_portal_PIN where status='Active' "


const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{
window.location.reload()
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
}) 
    
}

    const tablePinHeader = [

        {dataField: 'code', text: 'Action',editable:false, formatter: (cell, row)=>row.studentCode!==''?<div>
        <a href="#!" data-toggle="modal"  className='btn btn-warning btn-sm'  data-target={`#warningdialog-${cell}`} ><i className="icofont icofont-delete-alt"></i> RESET PIN</a> 

<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleResetPIN(cell)} mID={cell} />
    </div>:'', editable: false},
            {dataField: 'pin', text: 'Portal PIN', sort: true, editable:false},
            {dataField: 'studentName', text: 'Student', sort: true, editable:false}, 
            {dataField: 'admissionNumber', text: 'Admission Number', sort: true, editable:false}, 
            {dataField: 'status',  text: 'Status', editable:false, formatter:(cell, row)=>row.status==='Active'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i> {cell}</div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i> {cell}</div>}         
         ];


         
const BasicTable = (props)=>{
    
    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData();
    fd.append("jwt", Token); 
            fd.append('columnField', newValue);
            fd.append('column', column.dataField);
            fd.append('ID', ID);
          
           let url = ServerUrl+'/updateById_controller/tbl_portal_PIN'+
          axios.post(url, fd, config)
         // .then(result => console.log(result.data))
          .then()
          .catch((error)=>console.log(error)) 
          
        } 

        const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
    const options = {
        showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
    { text: 'All', value: props.data.length }]
        
        };
        const { SearchBar } = Search;

    return  <ToolkitProvider search  className="table-responsive"
                keyField='code' data={ props.data } columns={ props.columns } >
                    
                    {
                        props => (
                        <div >
                       

                       <div className="form-group">
                           
       
 <SearchBar
                        { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
 
 <button type="button"  data-toggle="modal"   data-target={`#warningdialog-123`}  className="btn btn-danger "  ><i className="fa fa-times"></i> RESET ALL PIN </button>
						
                        <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>DeleteActive('123')} mID={'123'} />


 <div className="dt-buttons btn-group pull-right"> 
       

<div style={{ display:'flex', flex:1, flexDirection:'row' }}>
    
						<input type="number" name='total' autoComplete='off' placeholder='Number of pin'  className={errors.total  ? 'form-control form-control-danger' : 'form-control'}   value={total} onChange={handleChange} />
                        
                        <button type="button" onClick={generatePin}  className="btn btn-success" ><i className="fa fa-key"></i> Add  PIN </button>&nbsp;&nbsp;


                                   <form method='POST' action={ServerUrl+'/printout/portal_pin.php?token='+userToken} target='_blank' >
       
       <input type='hidden' name='jwtToken' value ={Token} />
       <input type='hidden' name='allPin' value ={JSON.stringify(portalPin.filter(item=>item.status==='Active'), null, 2)} />
      
       <button type="submit" className="btn btn-info  " > <i className="fa fa-print"></i> </button>

</form>


</div>   </div>
                                   </div>


                            <BootstrapTable
                            { ...props.baseProps }
                                        striped
                                    hover
                                    condensed
                                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                pagination={paginationFactory(options) }
                                cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                            />
                        </div>
                        )
                    }
</ToolkitProvider>
}


const  generatePin =()=>{  
    
    
    let errors = {};   
    if(!total){
        errors.total ='Please enter total pin';
    }  
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

        var value = [];
        for (var i = 0, l = total; i < l; i++) {
    
          value.push({
            code:'',
            pin:generateCardNo(10)
        });
        }



        const fd = new FormData();
    fd.append("jwt", Token);
       
            fd.append('pin', JSON.stringify(value));
            fd.append('token', Token);

        setNotice({...notice, 
          isLoading: true}) 
           let url = ServerUrl+'/save_controller/tbl_generate_pin'
          axios.post(url, fd, config)
          .then(response =>{
            
            if (response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
            } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }  })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        .finally(()=>{
          setNotice({...notice, isLoading: false}) 
          setTotal('')
          fetchPin()
    } )

}

}





const fetchPin =()=>{
    setNotice({...notice, isLoading: true}) 
var sql ="SELECT p.pin, p.studentCode, s.studentName, s.admissionNumber, p.dateCreate, p.dateUsed, p.code, p.status, p.ID from tbl_portal_PIN p LEFT JOIN tbl_students s on s.code = p.studentCode "


const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{

if(response.data.length!==0){
  
    console.log(response.data)
    setPortalPin(response.data);
}
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
}) 
    
}


useEffect(()=>{   
   //setIsAuthenticated(verifyRouter(location.pathname))
  fetchPin()
},[]);


        return (   <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Generate Portal PIN">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">Generate Portal PIN</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
                <div className="row  ">
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-th"></i> PIN List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


        <div className="card z-depth-0">
            <div className="card-block">
        <div className="col-md-12 table-responsive">
	
  <BasicTable data={portalPin}  columns={tablePinHeader}  />
   
   

	</div></div>

</div></div></div>


</div>
</div>
                

                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(PortalPin) 