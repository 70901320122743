import React, {useState, useEffect, useCallback, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, config, Token, selectStyles} from '../component/include'
import { ReminderTable } from '../component/dataTable'
import {Type} from 'react-bootstrap-table2-editor';
import { InputRequiredIcon, TextArea} from '../component/formControl'
import Select from 'react-select'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
 const FeeReminder =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [errors, setErrors] = useState({});
     
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
const [isSubmitting, setIsSubmitting] = useState(false); 
    const [feecharge, setFeecharge] = useState([]);
    const Initials = 
        { 
        dueDate:'', 
            firstReminder:'',
            secondReminder:'',
            afterDueReminder:'',
            dueMessage:'',
            feecharge:[],
            firstMessage:'',
            secondMessage:'',
            afterMessage:''
        }
    
    const [feeReminder, setFeeReminder] = useState(Initials)
const [reminder, setReminder] = useState([])
    const handleReset=()=>{
        setFeeReminder(Initials);
    }

    const handleFetchReminder =()=>{
        
        setNotice({...notice, isLoading: true}) 
	var sql ="Select CONCAT(f.sessionCode, ' ', f.feesCharge) as fees, r.ID, r.code, r.dueDate, r.firstReminder, r.secondReminder,	r.afterDueReminder,	r.dueMessage,	r.firstMessage,	r.secondMessage, r.afterMessage from tbl_fees_charge f, tbl_fee_reminder r where r.feeType = f.code order by r.ID DESC" ;

    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
    setReminder(result.data)
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice, isLoading: false})
	
})


    }

    const fetchFeecharge =()=>{
        const fd = new FormData();
				  fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_fees_charge'
        axios.post(url, fd, config).then(result=>setFeecharge(result.data))
    }



    const getDateBefore =(num)=>{

        if(feeReminder.dueDate==='' || undefined){
            return 'Select Due Date First';
        }else{
        var date = new Date(feeReminder.dueDate);
                date.setDate(date.getDate() - Number(num));       
        return date.toISOString().substr(0,10)
        }
        
        }

    
    const getDateAfter =(num)=>{
        if(feeReminder.dueDate==='' || undefined){
            return 'Select Due Date First';
        }else{
        var date = new Date(feeReminder.dueDate);
                date.setDate(date.getDate() + Number(num));       
        return date.toISOString().substr(0,10)
        }
            }
    const handleSubmit =(event)=>{
        event.preventDefault();
        setErrors(ValidateInput(feeReminder));
        setIsSubmitting(true); 
    
    } 
const ValidateInput=(values)=>{
    let errors = {};     
    
    if(!values.dueDate){
        errors.dueDate ='Please select due date';
    }
    if(!values.dueMessage){
        errors.dueMessage ='Please enter due message';
    }
    if(values.feecharge.length===0){
        errors.feecharge ='Please select Fee Charge';
    }
    
    return errors;
    }


        
    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        submit()
        }
    },[errors])

            
function submit(){  
 
    let formData = feeReminder; 
    const fd = new FormData();
	fd.append('jwt', Token); 
    for(let k in formData){
        fd.append(k,formData[k]);
    }; 
    fd.append('code',  'rm'+Math.random().toString(36).substr(2,9));
    fd.append('selectedFee',  feeReminder.feecharge.value);
fd.append('firstDate',  getDateBefore(feeReminder.firstReminder.value));
fd.append('secondDate',  getDateBefore(feeReminder.secondReminder.value));
fd.append('afterDate',  getDateAfter(feeReminder.afterDueReminder.value));

   setNotice({...notice,   isLoading: true}) 

       let url = ServerUrl+'/save_controller/tbl_fee_reminder'
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
      handleReset();
      handleFetchReminder() 
              setNotice({...notice, 
                  isLoading: false
              })
          }) 

}

    
    

    const handleSelect = (option, action)=>{
        setFeeReminder({...feeReminder, [action.name]: option });
        setErrors({...errors, [action.name]:''})
    };



   const handleChange =(event)=>{
    const {name, value} = event.target; 
setFeeReminder({...feeReminder, [name] : value});
setErrors({...errors, [name]:''})
    
}



const handleDelete = (ID)=>{  
    setNotice({...notice,  isLoading: true}) 
       let close =   document.getElementById('btnWarningDialog-'+ID)
          close.click();
          const fd = new FormData();
          fd.append('jwt', Token);
       fd.append('ID', 'ID')
		fd.append('data', ID)

        let url = ServerUrl+'/delete_controller/tbl_fee_reminder'
       axios.post(url, fd, config)
       .then(response =>{
        if(response.data.type ==='success'){
           Alerts('Saved!', 'success', response.data.message)
               } else{
           Alerts('Error!', 'danger', JSON.stringify(response.data))
               }   
       })
       .catch((error)=>{
         Alerts('Error!', 'danger', error.message)
       }).finally(()=>{
        handleFetchReminder()
           setNotice({...notice, 
               isLoading: false
           })
       }) 
}



const tableHeader = [
    {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
        <a href="#!" data-toggle="modal"   data-target={`#warningdialog-${row.ID}`}  className='btn btn-danger btn-sm'   title="Delete"><i className="icofont icofont-delete-alt"></i></a>
     
     <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.ID)} mID={row.ID} /> 
    </div>, editable: false},
    {dataField: 'fees', text: 'Fee Charge', sort: true, editable: false},
    {dataField: 'dueDate', text: 'Due Date', sort: true, validator: (newValue, row, column) => {
        if (!newValue) {
          return {
            valid: false,
            message: 'Date Due field is required'
          };
        }
        return true;
      },
      editor: { type: Type.DATE } }, 
    {dataField: 'firstReminder', hidden: true, text: 'First Reminder Date',  sort: true, editor: {
        type: Type.DATE  } },
    {dataField: 'secondReminder', hidden: true, text: 'Second Reminder Date', sort: true, editor: {
        type: Type.DATE  }  }, 
    {dataField: 'afterDueReminder', text: 'After Due Date',  sort: true, editor: {
        type: Type.DATE } },
    {dataField: 'dueMessage', text: 'Due Date Message',  formatter:(cell)=><span style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>, sort: true, validator: (newValue, row, column) => {
        if (!newValue) {
          return {
            valid: false,
            message: 'Due Message is required'
          };
        }
        return true;
      }},
    {dataField: 'firstMessage', text: 'First Reminder Message', formatter:(cell)=><span style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>,  sort: true, hidden: true},
    {dataField: 'secondMessage', text: 'Second Reminder Message',  formatter:(cell)=><span style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>, sort: true, hidden: true },
    {dataField: 'afterMessage', text: 'After Due Date Message',  formatter:(cell)=><span style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>, sort: true, hidden: true },
    {dataField: 'date_created', text: 'Created On', sort: true, editable: false,hidden: true }
 ];



 useEffect(()=>{      
     
    setIsAuthenticated(verifyRouter(location.pathname))     
    fetchFeecharge()
    handleFetchReminder()
},[]);
        return (    <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Fees Reminder">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Fees Reminder</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
<div className="page-body">
								<div className="row  ">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>Automatic Fee Reminder</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
			<div className="card z-depth-0">
            <div className=" well">
        <h3><p className="alert alert-info border-info background-info" >Automatic Fee Reminder</p></h3>
                            
            <p>	School can define as many fee reminders depending on fee charge, A particular reminder will be sent only once to a recipient.  If a student has no dues , then no message will be sent. Each Fee reminder can have following Options. </p>
            <p  >Fee Charge : <strong > When you choose fee charge in which you want student to receive due notice for, amount due will be added, if no fee charge is selected message will not contain amount due for each student and entered message will be sent to every student.</strong></p>

            <p>Due Date ( Mandatory) : <strong  >Date till which student fee needs to be remitted to school. A Reminder will be sent on this date automatically through email as well as SMS. </strong></p>
                            
            <p>Due Date Message :<strong  >Message to be sent on Fee due date </strong></p>
            <p  >First Reminder ( Optional ):<strong  >First Reminder that Fee is due for the student. ( 15 to 8 days prior to due date ) </strong></p>


            <p>First Reminder Message : <strong >Message to be sent on First Reminder date</strong> </p>
            <p >Second Reminder( Optional ) : <strong  >Second Reminder for Fees Due (7 to 1 days prior to due date )</strong></p>
            <p  >Second Reminder Message : <strong > Message to be sent on Second Reminder date </strong></p>
                            
            <p  >After Due Date( Optional ) : <strong >After Due Date for Fees Due (7 to 1 days prior to due date )</strong></p>
            <p  >After Due Date Message : <strong > Message to be sent on After Due Date </strong>
                            </p>
            
        </div>
    </div>
</div>
						
      </div> </div></div>
                                


<div className="row" id="addreminder">
<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Fee Reminder</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="col-md-12">
		  <div className="card z-depth-0">
            <div className="card-block">

		<fieldset>
                    <div className="row">
        <section className="col-md-6">
        <div className="form-group">

<label >Fee Charge <span style={{color:'red'}}>*</span></label>
<Select  options={feecharge&&feecharge.map((list, idx)=> {
                               return {key:idx, value: list.code, label:list.feesCharge, session:list.sessionCode }
                             })
                        } 
                        getOptionLabel={option =>`${option.session}  ${option.label}`}       
onChange={handleSelect} className={errors.feecharge ? 'form-control form-control-danger' : ''} name="feecharge" value={feeReminder.feecharge} style={selectStyles} />  
              <span style={{color:'red'}}>{errors.feecharge && errors.feecharge}</span>          
                    </div>
                       

                    <div className="form-group">
<label>Due Date <span style={{color:'red'}}>*</span></label>
                        <input type='date' className={errors.dueMessage  ? 'form-control form-control-danger' : 'form-control'} name='dueDate' value={feeReminder.dueDate}  handleChange={handleChange} />

                        <span style={{color:'red'}}>{errors.dueDate && errors.dueDate}</span>
                        </div>
        
            <div className="form-group">
    <label >First Reminder</label>
    <Select  options={[
                    { value: '15', label: '15 Days Before Due Date' },
                    { value: '14', label: '14 Days Before Due Date' },
                    { value: '13', label: '13 Days Before Due Date' },
                    { value: '12', label: '12 Days Before Due Date' },
                    { value: '11', label: '11 Days Before Due Date' },
                    { value: '10', label: '10 Days Before Due Date' },
                    { value: '9', label: '9 Days Before Due Date' },
                    { value: '8', label: '8 Days Before Due Date' }
                ]} 
onChange={handleSelect}  name="firstReminder" value={feeReminder.firstReminder}  /> 
                </div>

                        <div className="form-group">
    <label >Second Reminder</label>
    <Select  options={[
                    { value: '7', label: '7 Days Before Due Date' },
                    { value: '6', label: '6 Days Before Due Date' },
                    { value: '5', label: '5 Days Before Due Date' },
                    { value: '4', label: '4 Days Before Due Date' },
                    { value: '3', label: '3 Days Before Due Date' },
                    { value: '2', label: '2 Days Before Due Date' },
                    { value: '1', label: '1 Day Before Due Date' }
                ]} 
onChange={handleSelect}  name="secondReminder" value={feeReminder.secondReminder} />
                            
            </div>
                        <div className="form-group">
    <label >After Due Date</label>
    <Select  options={[
                    { value: '7', label: '7 Days After Due Date' },
                    { value: '6', label: '6 Days After Due Date' },
                    { value: '5', label: '5 Days After Due Date' },
                    { value: '4', label: '4 Days After Due Date' },
                    { value: '3', label: '3 Days After Due Date' },
                    { value: '2', label: '2 Days After Due Date' },
                    { value: '1', label: '1 Day After Due Date' }
                ]} 
            
onChange={handleSelect}  name="afterDueReminder" value={feeReminder.afterDueReminder}   />
                        </div>
                       
                       
                       
                    </section>
                    <section className="col-md-6"><br/>
                    
                    <TextArea name="dueMessage" className={errors.dueMessage  ? 'form-control form-control-danger' : 'form-control'} label="Due Date Message" textValue={feeReminder.dueMessage} handleChange={handleChange} rows="2" />
                    <span style={{color:'red'}}>{errors.dueMessage && errors.dueMessage}</span> 

                   <TextArea name="firstMessage" className="form-control" label="First Reminder Message" textValue={feeReminder.firstMessage} handleChange={handleChange} rows="2" />

                  <TextArea name="secondMessage" className="form-control" label="Second Reminder Message" textValue={feeReminder.secondMessage} handleChange={handleChange} rows="2" />

                   <TextArea name="afterMessage" className="form-control" label="After Due Date Message" textValue={feeReminder.afterMessage} handleChange={handleChange} rows="2" />

                 

                    </section>
                
                </div>
                  </fieldset>
<footer className="pull-right">
																
<button type="button"   className="btn btn-primary " onClick={handleSubmit}>Save</button>

							</footer>
							</div>



                            </div></div>
							</div></div>
                            </div>

                            <div className="row  ">
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>Fee Reminder List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="col-md-12">
		  <div className="card z-depth-0">
            <div className="card-block">
        <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <ReminderTable data={reminder} fetchdata={handleFetchReminder} columns={tableHeader}  />}
            </div>
</div></div>
</div>

</div>
</div>
</div>
							</div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>
</>
 );
        
}

export default React.memo(FeeReminder) 