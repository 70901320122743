import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import PageHeader from '../component/pageheader'
import {LoadEffect, TableLoader } from '../component/loader'
import {Alerts, WarningModal} from '../component/notify'
import {ServerUrl, Token, config, ImagesUrl, Currency, FormatNumber} from '../component/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { getAge, longDate } from '../component/globalFunction'
import Select from 'react-select'
import { formatGroupLabel} from '../component/globalFunction'

import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import Error403 from '../settings/error403'

import { useSelector } from 'react-redux'


 const AdmissionRequest =()=> {
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);

	 let myRef = useRef()
     const structure = useRef(null)
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    
    const [search, setSearch]= useState({
        fromDate:'',
        toDate:'',
        applicationType:'all',
        gender:'all',
        applicationID:'',
        admissionStatus:'all'
    })
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });

    const [content, setContent] = useState([]);
    const [allApplication, setAllApplicationType]= useState([])
    const [pattern, setPattern]=useState('')

    const [errors, setErrors] = useState({});
    const [feecharge, setFeecharge] = useState([]);
    const [students, setStudents] = useState([]);  
    const [classFee, setClassFee] =  useState([]);
    const [totalFees, setTotalFees] =  useState(0);
const [isDiplaying, setIsDiplaying] = useState(false);

const [actualFee, setActualFee] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
   
    
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const [studentTransfer, setStudentTransfer]= useState({
        admissionNumber:'',
        admission:false,
        feecharge:'',
        remark:'',
        feeStructure:'',
         toClass:[],
         student:'',
})




    const fetchFeecharge =()=>{
      const fd = new FormData();
        fd.append("jwt", Token);
      let url = ServerUrl+'/fetch_controller/tbl_fees_charge'
      axios.post(url, fd, config).then(result=>setFeecharge(result.data))
  }

  const handleSelect = (option, action)=>{
    setStudentTransfer({...studentTransfer, [action.name]: option});
    setErrors({...errors, [action.name]:''})
     }

     const handleSelectTo = (option, action)=>{
        setStudentTransfer({...studentTransfer, [action.name]: option
        });
        setErrors({...errors, [action.name]:''})
        setIsDiplaying(true);
        handleFetchClassFee(option.value);
        structure.current.scrollIntoView({ behavior: "smooth" });
         }



    const handleChangeFee =(index, e)=>{
    const fees =JSON.parse(JSON.stringify(classFee))   
    const value =e.target.value;
    fees[index].price = value;

    var totalFee = 0;
    fees.map(item=>totalFee+=Number(item.price))
    setClassFee(fees)
    setTotalFees(totalFee)
    
        }


const getActualFee = (ID) =>{        
    const result = actualFee && actualFee.filter(list=>list.ID===ID);
    const answer =result?result.map(lst=>lst.price):[]
    return   answer

    }

   



         const  handleFetchClassFee =(section)=>{
            const fd = new FormData();
            fd.append("jwt", Token);
            let url = ServerUrl + '/fetchField/tbl_class_fees/feesDetail/sectionID/'+section
                setNotice({...notice, 
                    isLoading: true
                });
                axios.post(url, fd, config).then(result => {   
                    if(result.data.length!==0){
                        var totalFee = 0;
                        result.data.map(item=>totalFee+=Number(item.price))
                        setClassFee(result.data);
                    setActualFee(result.data);
                    setTotalFees(totalFee)
                    }  else{
                        setClassFee('');
                    setActualFee('');
                    setTotalFees(0)
                    }
                    
                })
                .catch((error)=>{
                    Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                    setNotice({...notice, 
                        isLoading: false
                    });
                })
            }


    const SearchTable =(event)=>{
       event.preventDefault();
       fetchContent();
    }
    


    const fetchApplication =()=>{


        var sql ="select t.applicationName, a.startDate, a.feeCharge, a.endDate, a.dateCreate, a.ID, s.startYear from tbl_admission_application t, tbl_school_admission a, tbl_school_session s  where s.ID = a.schoolSession and t.ID = a.applicationType order by a.ID DESC "


        const fd = new FormData();
    fd.append('jwt', Token);
    fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller';
        
        axios.post(url, fd, config).then(result=> setAllApplicationType(result.data))
    }

    


    const handleDelete =()=>{
          
        let dataId = []
        var newContent = JSON.parse(JSON.stringify(content));
        for (var i in newContent){
            if(newContent[i].status===true){
                dataId.push(newContent[i].code)
                
            }
        }
        let close =   document.getElementById('btnWarningDialog-delAll')
        if(dataId.length===0){
            Alerts('Error!', 'danger', 'Please select at least one applicant to delete')
              
        }else{
            setNotice({...notice, isLoading: true}) 
        var data = JSON.stringify(dataId, null, 2)

            const fd = new FormData();
            fd.append('data', data)
            fd.append('jwt', Token)
        let url = ServerUrl+'/deleteMultiple_controller.php?tablename=tbl_admission_request';
              axios.post(url, fd, config)
              .then(response =>{
               if(response.data.type ==='success'){
                  Alerts('Default!', 'default', response.data.message)
                  
                      } else{
                        setErrors({...errors, errorMessage: JSON.stringify(response.data)})
                 // Alerts('Error!', 'danger', response.data)
                      }   
              })
              .catch((error)=>{
                setErrors({...errors, errorMessage: error.message})
              }).finally(()=>{
                  setNotice({...notice,  isLoading: false })
                  fetchContent()
              }) 
            }
            close.click();
    }

   

    const fetchSchoolInfo =()=>{
        const fd = new FormData();
      fd.append('jwt', Token);
        let url = ServerUrl + '/fetch_controller/tbl_basic_details'
        axios.post(url, fd, config).then(result=>{   
       var patterns = result.data[0].admissionNumberPattern
       let newPattern = patterns.replace('{schoolAbbreviation}', result.data[0].schoolAbbreviation);
        setPattern(newPattern);
        })

    }

    const handleGenerateAdmissionNo=()=>{
        const fd = new FormData();
        fd.append('jwt', Token)
        let url = ServerUrl + '/fetchCount_controller.php?tablename=tbl_students' 
        axios.post(url, fd, config)
      .then(result => {    
        setStudentTransfer({...studentTransfer, admissionNumber:Number(result.data)+1 });
      })
    }


    const fetchContent =()=>{
        setNotice({...notice,   isDataFetching: true  });

        var sql ="select r.ID, t.applicationName, a.feeCharge, s.startYear,  r.amountPaid, r.dateSubmitted, r.applicationID, r.dateCreate, r.passport, r.admissionStatus, r.studentName, r.paymentStatus, r.gender, r.email,  r.birthDate, r.placeOfBirth, r.submitted, r.country, r.stateCode, r.lga, r.schoolAttended, r.religion, r.tribe, r.languageSpoken, r.code, r.address, r.fatherName, r.fatherPhone, r.motherName, r.motherPhone, r.disabilityStatus, r.disabilityDetails, r.transferImage, r.recordImage, r.birthImage, r.isEmailVerified, r.schoolAttended  from tbl_admission_request r, tbl_admission_application t, tbl_school_admission a, tbl_school_session s  where s.ID = a.schoolSession and t.ID = a.applicationType and r.applicationType = a.ID  " ;

        if(search.fromDate.length!==0 && search.toDate.length!==0 ){
            sql = sql + ' and r.dateCreate between "'+search.fromDate+'" and "'+search.toDate+'"'
        }

        if(search.applicationType !== 'all'){
            sql = sql + " and r.applicationType = '"+search.applicationType+"'"
        }

        if(search.gender !=='all'){
            sql = sql + '  and r.gender = "'+search.gender+'"'
        }


        if(search.applicationID !==''){
            sql = sql + '  and r.applicationID = "'+search.applicationID+'"'
        }

        if(search.admissionStatus !=='all'){
            sql = sql + '  and r.admissionStatus = "'+search.admissionStatus+'"'
        }

        sql = sql + '  order by r.ID DESC'

          
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        
        axios.post(url, fd, config).then(result=>{ 
            if(result.data.length!==0){
                var options = result.data;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {


    value.push({ID:options[i].ID, 
        applicationID:options[i].applicationID, 
        birthDate:options[i].birthDate,
        applicationName:options[i].applicationName,
        amountPaid:options[i].amountPaid,
        startYear:options[i].startYear,
        admissionStatus:options[i].admissionStatus,
        email:options[i].email,
        fatherName:options[i].fatherName,
        gender:options[i].gender,
        placeOfBirth:options[i].placeOfBirth,
        country:options[i].country,
        stateCode:options[i].stateCode,
        lga:options[i].lga,
        tribe:options[i].tribe,
        religion:options[i].religion,
        languageSpoken:options[i].languageSpoken,
        address:options[i].address,
        fatherPhone:options[i].fatherPhone,
        motherName:options[i].motherName,
        motherPhone:options[i].motherPhone,
        isEmailVerified:options[i].isEmailVerified,
        schoolAttended:options[i].schoolAttended,
        code:options[i].code,
        
        admissionNumber:'',

        submitted:options[i].submitted,
        studentName:options[i].studentName, 
        passport:options[i].passport, 
        dateCreate:options[i].dateCreate, 
        dateSubmitted:options[i].dateSubmitted,
        paymentStatus:options[i].paymentStatus,
         gender:options[i].gender, 
         status:false});
    }
                setContent(value.sort((a, b) =>(a.ID > b.ID) ? 1 : -1))
                
            }else{
                setContent([])
            }
            
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
            //Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }

  const ChangeAdmission =()=>{
    var options = content;

    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {

        let pat = pattern&&pattern.replace('{AdmissionSession}', options[i].startYear);

    value.push({ID:options[i].ID, 
        applicationID:options[i].applicationID, 
        birthDate:options[i].birthDate,
        applicationName:options[i].applicationName,
        amountPaid:options[i].amountPaid,
        startYear:options[i].startYear,
        admissionStatus:options[i].admissionStatus,
        email:options[i].email,
        fatherName:options[i].fatherName,
        gender:options[i].gender,
        placeOfBirth:options[i].placeOfBirth,
        country:options[i].country,
        stateCode:options[i].stateCode,
        lga:options[i].lga,
        tribe:options[i].tribe,
        religion:options[i].religion,
        languageSpoken:options[i].languageSpoken,
        address:options[i].address,
        fatherPhone:options[i].fatherPhone,
        motherName:options[i].motherName,
        motherPhone:options[i].motherPhone,
        isEmailVerified:options[i].isEmailVerified,
        schoolAttended:options[i].schoolAttended,
        code:options[i].code,
        
        admissionNumber:pat.replace('{AdmissionNumber}', Number(studentTransfer.admissionNumber+(i+1))),

        submitted:options[i].submitted,
        studentName:options[i].studentName, 
        passport:options[i].passport, 
        dateCreate:options[i].dateCreate, 
        dateSubmitted:options[i].dateSubmitted,
        paymentStatus:options[i].paymentStatus,
         gender:options[i].gender, 
         status:false});
    }
    setStudents(value.filter(item=> item.admissionStatus==='Pending'&&item.submitted==='true'))
    setStudentTransfer({...studentTransfer, admission:!studentTransfer.admission})


  }


  const handleChangeStudent =(event)=>{
        
    const {name, value} = event.target;
        setStudentTransfer({...studentTransfer, [name] : value });
        setErrors({...errors, [name]:''})
        
       }

  

  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
}

  const handleCheckOne =(e)=>{  
    
    let {name, value} = e.target

    const newContent = [...content]
    const otherContent = newContent.filter(item=>item.applicationID!==name)
    const custom = newContent.filter(item=>item.applicationID===name)
    custom[0].status = value==='true'?false:true
    const currentContent = custom.concat(otherContent)
  setStatus({...status, checkAll:false})
   setContent(currentContent.sort((a, b) =>(a.ID > b.ID) ? 1 : -1)) 
        
       }
    

  const handleCheckAll =()=>{
    var newContent = [...content]
    for (var i in newContent){
        newContent[i].status=!status.checkAll
    }
    setStatus({...status, checkAll:!status.checkAll})
    setContent(newContent.sort((a, b) =>(a.ID > b.ID) ? 1 : -1))
 }

 
  const generalCheck =()=><div className="checkbox-fade fade-in-success">
  <label>
<input type="checkbox" value="" name="general" checked={status.checkAll} onChange={handleCheckAll} />
      <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
  </label>
</div> 

 

    const tableHeader = [
        {dataField: 'applicationID',  text: generalCheck(), editable: false, formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
        <label>
    <input type="checkbox" value={row.status} name={row.applicationID}  checked={row.status} onChange={(e)=>handleCheckOne(e)} />
            <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
        </label>
    </div>},

{dataField: 'passport', text: 'Passport', editable: false, formatter:(cell, row)=><img className="img-50" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/"+row.gender+".png"}} src={ImagesUrl+'/students/'+cell} title={row.studentName}  alt={row.studentName} />},

{dataField: 'applicationName', text: 'Type', editable: false, formatter:(cell, row)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{row.startYear}  {row.applicationName}</span>},

    {dataField: 'studentName', text: 'Name', formatter:(cell, row)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell} - {row.applicationID}</span>},
    {dataField: 'birthDate',  text: 'Age',  sort: true, editor: {
        type: Type.DATE
      }, formatter:(cell)=>getAge(cell)},
    {dataField: 'amountPaid', text: 'Amount Paid', formatter:(cell, row)=>Currency+FormatNumber(cell)},

    {dataField: 'admissionStatus', text: 'Status', sort: true, editable: false, formatter:(cell)=><span className={`badge 
        ${cell==='Pending'?'badge-danger':'badge-success'}`}>{cell}</span>},

      {dataField: 'dateSubmitted', text: 'Date Submitted', editable: false, formatter: (cell, row)=><span>{cell!=='0000-00-00'?longDate(cell):'Not Yet'}</span>},

        {dataField: 'ID', text: 'Action', formatter: (cell, row)=>
        
        <form method='POST' id={row.applicationID} action={ServerUrl+'/printout/student_application_summary.php?applicationID='+row.applicationID+'&token='+row.email} target='_blank'>
                <input type='hidden' name='jwtToken' value ={Token} />
                {row.dateSubmitted!=='0000-00-00'?
<button type="submit" className="btn btn-info   no-print btn-sm" > <i className="fa fa-eye"></i> Preview</button>
              :''}
        
        </form>,
        
        editable: false}
       
     ];

    
     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, ID)=>{       
         const fd = new FormData(); 
             fd.append('newValue', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
             fd.append('jwt', Token);
            let url = ServerUrl+'/updateCustom_controller/tbl_admission_request'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then(  )
           .catch((error)=>Alerts('Error!', 'danger', error.message)) 
         } 
         const { SearchBar } = Search;
         const { ToggleList } = ColumnToggle;
         //const { ExportCSVButton } = CSVExport;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '70', value: 70}, {text: '150', value: 150}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                                              

              <div className="dt-buttons btn-group pull-right"> 

            <button type="button" onClick={ChangeAdmission} className="btn btn-outline-info" > <i className="fa fa-graduation-cap"></i>Admit Student </button>

            <button className="btn btn-outline-danger delete" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`} > <i className="icofont icofont-delete-alt"></i>Delete (selected)</button>
    <div className="btn-group dropdown-split-primary ">




        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
          
        <form method='POST' action={ServerUrl+'/excel_controller.php?api=studentsapplication'} target='_blank'>
        <input type='hidden' name='gender' value ={search.gender} />

<input type='hidden' name='fromDate' value ={search.fromDate} />
<input type='hidden' name='toDate' value ={search.toDate} />

<input type='hidden' name='applicationType' value ={search.applicationType} />
<input type='hidden' name='applicationID' value ={search.applicationID} />

<input type='hidden' name='admissionStatus' value ={search.admissionStatus} />
<input type='hidden' name='jwtToken' value ={Token} />

                <input type='hidden' name='jwt' value ={Token} />
               
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
        
        </form>

        <form method='POST' action={ServerUrl+'/printout/student_application.php?token='+new Date()} target='_blank'>
                <input type='hidden' name='gender' value ={search.gender} />

                <input type='hidden' name='fromDate' value ={search.fromDate} />
                <input type='hidden' name='toDate' value ={search.toDate} />

                <input type='hidden' name='applicationType' value ={search.applicationType} />
                <input type='hidden' name='applicationID' value ={search.applicationID} />

                <input type='hidden' name='admissionStatus' value ={search.admissionStatus} />
                <input type='hidden' name='jwtToken' value ={Token} />

        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
        
        </form>

        </div>
    </div> 
    </div>  

      
                                 </div>
                                 
                     <BootstrapTable 
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      




      const handleSubmit = event =>{
        event.preventDefault();
        setErrors(ValidateInput(studentTransfer)); 
        setIsSubmitting(true);
    } 

const ValidateInput =(values)=>{
	let errors = {};   
        

    if(values.toClass.length===0){
        errors.toClass ='Please select to Class';
	}
    
    if(students.length===0){
        errors.student ='There are no student in the selected class ';
       // window.scrollTo(0,0);
    } 

    if(!Array.isArray(values.student)){
        errors.student ='Please select student to be admitted';
       // window.scrollTo(0,0);
    }

    if(values.feecharge.length===0){
        errors.feecharge ='Please select fees charge';
        //window.scrollTo(0,0);
    }

    if(classFee.length===0){
        errors.feeStructure ='There is no school fees define ';
    } 
    
    
    return errors;
	}
	
	
useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])

function submit(){  

    window.scrollTo(0,0);
     
    setNotice({...notice, isLoading: true}) 

      const fd = new FormData(); 
    fd.append("jwt", Token);
       fd.append('classId', studentTransfer.toClass.classID);
        fd.append('sectionId', studentTransfer.toClass.value);
        fd.append('feeStructure', JSON.stringify(classFee, null, 2));
        fd.append('feeCharge', studentTransfer.feecharge.value);
        fd.append('remark', studentTransfer.remark);
        fd.append('totalFee', totalFees);
        fd.append('studentList', JSON.stringify(studentTransfer.student, null, 2));
        fd.append('ClassName', studentTransfer.toClass.label);
       

      let url = ServerUrl+'/save_controller/tbl_student_admission'
	  axios.post(url, fd, config)
	  .then(response =>{
		if (response.data.type ==='success'){
            setErrors({...errors, successMessage: response.data.message})
			
		} else{
            setErrors({...errors, errorMessage: JSON.stringify(response.data)})
		
	}  
	})
	.catch((error)=>{
        setErrors({...errors, errorMessage: JSON.stringify(error.message)})
	})
	.finally(()=>{
	  setNotice({...notice, isLoading: false}) 
      setIsDiplaying(false);
      setIsSubmitting(false); 
      setStudentTransfer({...studentTransfer,
        admission:false,
        feecharge:'',
        remark:'',
        feeStructure:'',
         toClass:[],
         student:'',
    });
    fetchContent()
})   
}

   


     useEffect(()=>{
         
        setIsAuthenticated(verifyRouter(location.pathname))
        fetchSchoolInfo()
        handleGenerateAdmissionNo()
        fetchContent()
        fetchApplication()
        fetchFeecharge()
       },[]);


     

        return (<>
   <div  className="main-body">
             {isAuthenticated?   <div className="page-wrapper">
                <PageHeader title="Admission Request List">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Admission Request</a> </li>
                    </PageHeader>


                    {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
{/* The Page Body start here */}
<div className="page-body">
         
         <WarningModal message='Are you really sure? Once deleted you cannot get it back' handleAction={handleDelete} mID={'delAll'} /> 

        

{errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}




			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
        <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <label  className='pull-right text-bold'>From Date</label>
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="fromDate" value={search.fromDate} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <label  className='pull-right text-bold'>To Date</label>
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}   name="toDate" value={search.toDate} />
                        </div>
                    </div>
                </div>
                </div>

                <div className="row"> 
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <label  className='pull-right text-bold'>Application Type</label>
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                  <select  name="applicationType" value={search.applicationType} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        {allApplication&&allApplication.map((data, id)=><option key={id} value={data.ID} >{data.startYear+' '+data.applicationName}</option>)}
                    </select>
                        </div>
                    </div>
                </div>

            

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <label  className='pull-right text-bold'>Gender</label>
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="gender" value={search.gender} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                </div>
                    </div>
                </div>
            </div>
            <div className="row">                   
                

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <label  className='pull-right text-bold'>Application ID</label>
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                <input type="text" className="form-control " placeholder="Application Number" onChange={handleChange}  name="applicationID" value={search.applicationID} />
                </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <label  className='pull-right text-bold'> Status</label>
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="admissionStatus" value={search.admissionStatus} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="Pending">Pending</option>
                        <option value="Admitted">Admitted</option>
                    </select>
                        </div>
                    </div>
                </div>
                </div>
               

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>





                        {studentTransfer.admission?    <div className="row  ">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit"></i> Student Admission</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather minimize-card icon-minuss"></i></li>
					<li><i className="feather icon-trash-2s close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
		<fieldset>
        <div className="row">
<div className="col-sm-12">

				
	<div className="card z-depth-0">
										<section className="card-block">
                                           
										
	<div className="form-group">
	
	<label >Fee Charge <span style={{color:'red'}}>*</span> </label>
	<Select  options={feecharge&&feecharge.map((list, idx)=> {
								   return {key:idx, value: list.code, label:list.feesCharge, session:list.sessionCode }
								 })
							} 
							getOptionLabel={option =>`${option.session}  ${option.label}`}       
	onChange={handleSelect} name="feecharge" value={studentTransfer.feecharge} className={errors.feecharge ? 'form-control form-control-danger' : ''}  /> 
	<span style={{color:'red'}}>{errors.feecharge && errors.feecharge}</span>
				</div>

	<div className="form-group">
						<label>Select Student</label>
						<Select isMulti options={
                            students.map((list, idx)=> {
                               return {key:idx, passport:list.passport, value: list.applicationID, label: list.applicationName, studentName:list.studentName, email:list.email, fatherName:list.fatherName, gender:list.gender, birthDate:list.birthDate, placeOfBirth:list.placeOfBirth, country:list.country, stateCode:list.stateCode, lga:list.lga, religion:list.religion, tribe:list.tribe, languageSpoken:list.languageSpoken, address:list.address, fatherPhone:list.fatherPhone, motherName:list.motherName, motherPhone:list.motherPhone, isEmailVerified:list.isEmailVerified, schoolAttended:list.schoolAttended, admissionNumber:list.admissionNumber, regSession:list.startYear }
                             })} 
                        getOptionLabel={option =>`(${option.label}) ${option.studentName} - ${option.value} `}
onChange={handleSelect} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={studentTransfer.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>
											
                                            
                                            
                 <div className="form-group">
						<label>To Class</label>
						<Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID,isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
                             })
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelectTo} className={errors.toClass ? 'form-control form-control-danger' : ''} name="toClass" value={studentTransfer.toClass}  /> <span style={{color:'red'}}>{errors.toClass && errors.toClass}</span>
												
										</div>
		</section></div></div>
                                        
                                        
                                        </div>
                                        {isDiplaying ? 		
							<div className='row' >

					<section className="col-md-12"><br/>
<label><b>Class Fee Structure</b></label>
	 
<div className="table-responsive" >
      <table className="table ">
        <tbody >

{classFee.length!==0 ? classFee.map((fee, idx)=> 
<tr key={idx}>
				<td>{fee.fees}<br/><span style={{fontSize: '10px',color: '#fa0000'}}>Actual Fee {Currency+FormatNumber(getActualFee(fee.ID))}</span></td>

				<td><input type="number" min="0" className="form-control" onChange={(e)=>handleChangeFee(idx, e)}  name="price[]" value={fee.price} /></td>
			</tr>
				):<tr><td colSpan="3">
					<div className="alert alert-danger background-danger">There are no fee structure define for the selected class, please define fee first</div>
					</td></tr>
			
			}
				</tbody>
				{classFee.length!==0 ?	<tfoot>
					<tr>
						<td colSpan='' className='text-right'><b>Total Fees:</b></td>
						<td><b>{Currency+FormatNumber(totalFees)}</b></td>
					</tr>
				</tfoot>:[]}
		</table>
		</div>
					</section>
					<section className="col-md-12">
					{classFee.length!==0 ? <table className="table ">
				<tbody >
			
			<tr>
			<td colSpan="3"><label>Remark</label>
				<textarea value={studentTransfer.remark} rows='2' name='remark' className='form-control' onChange={handleChangeStudent}> {studentTransfer.remark}</textarea>
					</td>
			

			</tr>
			</tbody></table>:[]}
			</section></div>	:""}
										
            <div ref={structure} />		</fieldset>

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary "><i className="fa fa-refresh"></i> Confirm Admission</button>
	
								</footer>				
							</div>
							</div>
							</div>
	                   
                            </div>:''}






							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Admission Request</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block" >
       	 <div className="row">
          <div className="col-sm-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
           
           
    
    </div></div></div>
			

							</div>
							</div>
	                   
                            </div>




                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 

 );
        
}

export default React.memo(AdmissionRequest) 