import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {config, ServerUrl, Token} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Select from 'react-select'
import { formatGroupLabel, getClassName } from '../component/globalFunction'

import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import Error403 from '../settings/error403'
const Subjects=()=>{

    const Initials = {
        section:[],
        subject:''
    }
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [subject, setSubject]=useState(Initials);
    const [subjectList, setSubjectList]=useState([]);
    const [allSubject, setAllSubject]=useState([]);
    const [errors, setErrors] = useState({});
   
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const [data, setData] =  useState([]);
    const [schoolClass, setSchoolClass]=useState([]);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
        
    function submit(){  
        

        var allClass = subject.section
        var classList =[]
        
        for (var i = 0, l = allClass.length; i < l; i++) {
            classList.push({classID:allClass[i].classID, sectionID:allClass[i].value });
            }
        var options = subject.subject;
        var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
    value.push({subjectID:options[i].value});
    }
        const allSubject = subjectList.concat(value)
        const newSubject =	allSubject.map(e=>e['subjectID'])
                        .map((e,i,final)=>final.indexOf(e)===i&&i)
                        .filter(e=>allSubject[e])
                        .map(e=>allSubject[e])




        setNotice({...notice,  isLoading: true}) 
            const fd = new FormData(); 
            fd.append('jwt', Token);
            fd.append('classID', subject.section.classID) 
            fd.append('sectionID', subject.section.value)

            fd.append('classList', JSON.stringify(classList, null, 2)) 
            fd.append('subjectDetails', JSON.stringify(newSubject)) 

             let url = ServerUrl+'/save_controller/tbl_class_subject';
            axios.post(url, fd, config)
            .then(response =>{
                if (response.data.type ==='success'){
                    Alerts('Saved!', 'success', response.data.message)
                } else if (response.data.type ==='info'){
                    Alerts('Update!', 'info', response.data.message)
                } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
            }  
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', JSON.stringify(error.message)) 
            })
            .finally(()=>{
              setNotice({...notice, isLoading: false}) 
            handleReset(); 
            setIsSubmitting(false)
            handleFetchClassSubject()
            }) 
    }

    const handleReset=()=>{
        setSubject(Initials);
    }

    const ValidateInput =(values)=>{
        let errors = {};     
        if(values.section.length===0){
            errors.section ='Please select class';
        }
        if(!Array.isArray(values.subject)){
            errors.subject ='Please select subject';
           // window.scrollTo(0,0);
        }
        
        return errors;
        }

        const handleSubmit =(event)=>{
            event.preventDefault();
            setErrors(ValidateInput(subject));
            setIsSubmitting(true); 
        }
    
        useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
            submit()
            }
        },[errors])
    
    

        const  handleFetchClassSubject =()=>{
            const fd = new FormData();
            fd.append('jwt', Token);
            let url = ServerUrl + '/fetch_controller/tbl_class_subject';
            axios.post(url, fd, config)
        .then(result =>   setData(result.data)
        )
        .catch((error)=>{
           // Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        }) }

        const  handleFetchAllSubject =()=>{
            const fd = new FormData();
            fd.append('jwt', Token);
            let url = ServerUrl + '/fetch_controller/tbl_subject';
            axios.post(url, fd, config)
        .then(result => setAllSubject(result.data))
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        }) }

    const  handleFetchClass =()=>{
        const fd = new FormData();
            fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1';
        axios.post(url, fd, config)
        .then(result => setSchoolClass(result.data))
        .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 
        }

  
    const handleSelect = (option, action)=>{
       setSubject({...subject, [action.name]: option});
       setErrors({...errors, [action.name]:''})

        if(action.name ==='section' && option!==null){
            var dt =data.filter(item=>item.sectionID===option.value)
     var ans = dt.length>0?JSON.parse(dt[0].subjectDetails):[]
     setSubjectList(ans)

       } 


    }

    const getSubjectName = (ID, field) =>{        
        const result = allSubject&&allSubject.filter(list=>list.code === ID);
        return   String(result.map(c=>c[field]));
        }

    
    const duplicateSubject =(classID, sectionID, subject)=>{
    
        const subjectList = JSON.parse(subject)

       let list = []

         for(var i=0; i<subjectList.length; i++){
             list.push({
                 key:i+1,
                 label:getSubjectName(subjectList[i].subjectID, 'subjectName'),
                 value:subjectList[i].subjectID
             })
         }
     
         const section = {
                     classID:classID,
                     isSection : "0",
                     label: getClassName(classID, sectionID, schoolClass),
                     value:sectionID
         }
         setSubject({...subject, section: section, subject:list}); 
     
        }

 const handleDelete =(ID)=>{
		let close =   document.getElementById('btnWarningDialog-'+ID)
		close.click();
		

		setNotice({...notice,  isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'ID')
		fd.append('data', ID)
        fd.append('jwt', Token);
	let url = ServerUrl+'/delete_controller/tbl_class_subject';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'default', response.data.message)
				  } else{
			  Alerts('Error!', 'danger', JSON.stringify(response.data))
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
            handleFetchClassSubject()
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 
}
    
    
    useEffect(()=>{      
        //setIsAuthenticated(verifyRouter(location.pathname))          
        handleFetchClass();
        handleFetchClassSubject()
        handleFetchAllSubject()
    },[]);


   
 
    const tableHeader = [
        
        {dataField: 'sectionID', text: 'Class', editable: false, formatter:(cell, row)=>getClassName(row.classID, row.sectionID, schoolClass)}, 
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div> 
            
       {/*  <button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${cell}`} title="Delete"><i className="fa fa-trash"></i></button> */}&nbsp;
         

        <button type="button" className="btn btn-warning btn-sm" onClick={()=>duplicateSubject(row.classID, row.sectionID, row.subjectDetails)} title="Duplicate Class Subject"><i className="fa fa-edit"></i> Modify</button>


        <WarningModal message='Once deleted, you will no be able to recover this record. Are you realy sure?' handleAction={()=>handleDelete(row.ID)} mID={row.ID} />  </div>, editable: false}
        
     ];

     const SubjectTable=(props)=>{
        

    const getSubjectName = (ID, field) =>{        
    const result = allSubject&&allSubject.filter(list=>list.code === ID);
    return   String(result.map(c=>c[field]));
    }



        const expandRow = {
            renderer: row => (
               
                <div>
                    
                   <p><b>Subject:</b> </p>
                  <div className="table-responsive">
            <table className="table ">
                  <thead style={{textAlign:'center'}}>
                      <tr >
                          <th  >Name</th>
                          <th >Abbreviation</th>
                      </tr>
                  </thead>
              <tbody >
             
                     {(JSON.parse(row.subjectDetails)).map((list, idx)=>{
                   return (
                    
              <tr key={idx}>
                  <td>{getSubjectName(list.subjectID, 'subjectName').toUpperCase()}</td>
                  <td>{getSubjectName(list.subjectID, 'abbreviation').toUpperCase()}</td>
              </tr>
        )} )  }      
              </tbody>
              </table> 
              </div> 
            </div>),
            showExpandColumn: true,
            expandByColumnOnly: true,
            expandColumnPosition: 'left',
            onlyOneExpanding: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return <button type='button' className='btn-inverse' title="Close All"><i className=" fa fa-minus-square"></i></button>
                  }
                  return <button type='button' className='btn-primary' title="View All"><i className=" fa fa-plus-square"></i></button>
                },
                expandColumnRenderer: ({ expanded }) => {
                  if (expanded) {
                    return <button type='button' className='btn-inverse' title="Close"><i className=" fa fa-minus-square"></i></button>
                  }
                  return <button type='button' className='btn-primary'  title="View"><i className=" fa fa-plus-square"></i></button>
                }
            
          } 
      
      
          const { SearchBar } = Search;
         
          const customTotal = (from, to, size) => (
             <span >&nbsp;Showing { from } to { to } of { size } items</span>
           );
        const options = {
            showTotal: false,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
        { text: 'All', value: props.data.length }]
           
          };
      
        return <ToolkitProvider search className="table-responsive"
                    keyField='ID' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            <div >
                                <SearchBar
                                { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                                
<div className="dt-buttons btn-group pull-right"> 
         
         <a href="/setup/subject" className="btn btn-outline-primary" ><i className="fa fa-pencil"></i>Create Subject</a>
      </div> 
                                <BootstrapTable
                                { ...props.baseProps }
                                        hover
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                    expandRow={expandRow } 
                                    
                                />
                            </div>
                            )
                        }
      </ToolkitProvider>
      }

        return ( 
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Assign Subject">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-desktop"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Class & Section</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Assign Subject</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                
								<div className="row  ">
                            <div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Assign Subject to Class</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<fieldset>
				<div className="row">
				<section className="col-md-12">
                <div className="form-group">
						<label >Class<span style={{color:'red'}}>*</span></label>
                       
                        <Select 
                       options={
                        schoolClass.map((list, idx)=> {
                           return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                               return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                           }) }
                         })
                         
                    } 
                    formatGroupLabel={formatGroupLabel}
onChange={handleSelect} className={errors.section? 'form-control form-control-danger' : ''}  name="section" value={subject.section}   /> 

                <span style={{color:'red'}}>{errors.section && errors.section}</span> 
				 </div>


                 <div className="form-group">
						<label >Subject<span style={{color:'red'}}>*</span></label>
                       
                        <Select isMulti
                        options={allSubject.map((item, idx)=> {
                                    return {key:idx, value: item.code, label: item.subjectName };
                                  })}   
onChange={handleSelect} className={errors.subject ? 'form-control form-control-danger' : ''}  name="subject" value={subject.subject}   /> 

                <span style={{color:'red'}}>{errors.subject && errors.subject}</span> 
				 </div>					
                </section>
            </div>
        </fieldset>	
                                
	<footer className="pull-right">
	
	<button type="button" className="btn btn-inverse " onClick={handleSubmit}>Save</button>
	
								</footer>		
							</div>
							</div>
							</div>



                            </div>

                            <div className="row">
         <div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>Listing All Subject</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">

        <div className="card-block">


<div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <SubjectTable data={data}  columns={tableHeader}  schoolClass={schoolClass} />}
	
	</div></div>

</div></div>

</div>


                </div>
          {/* The Page Body close here */}
          
            </div>
            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Subjects) 